import { Fragment } from "react";
export default Details;
function Details({ description }){
    return(
        <Fragment>
<div class="courseDescriptionSection mt-42">
                        <h1 class="courseDetailsHeading">Description</h1>
                        <div class="courseDescriptionDec">
                            <p className='course-des-text'>{description}</p>
                             </div>
                    </div>
        </Fragment>
    );
}