import { useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../Network/API"

export default function CourseQuiz(props) {
    const navigate = useNavigate()
    const [mark_loading, setMarkLoading] = useState(false);
    // https://www.codevertiser.com/quiz-app-using-reactjs/
    const [q_ans, setQAns] = useState(0)
    const [activeQuestion, setActiveQuestion] = useState(0)
    const [selectedAnswer, setSelectedAnswer] = useState('')
    const [showResult, setShowResult] = useState(false)
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null)

    let { idd } = useParams();
  
    const [result, setResult] = useState({
        score: 0,
        correctAnswers: 0,
        wrongAnswers: 0,
    })
    const quiz = props?.quizes

    const { id, title, questions, answare } = quiz[activeQuestion]

    const onClickNext = () => {
        setSelectedAnswerIndex(null)
        setResult((prev) =>
            selectedAnswer
                ? {
                    ...prev,
                    score: prev.score + 5,
                    correctAnswers: prev.correctAnswers + 1,
                }
                : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }
        )
        markQuiz(id)
        if (activeQuestion !== quiz.length - 1) {

            setActiveQuestion((prev) => prev + 1)


        } else {
            setActiveQuestion(0)
            setShowResult(true)
        }

        inputRef.current.value = "";
    }

    const onAnswerSelected = (correctAnswer, index) => {
        if(correctAnswer==""){
            setSelectedAnswerIndex(null)
            return;
        }
        setSelectedAnswerIndex(index)
        setQAns(correctAnswer)
        if (correctAnswer === answare) {
            setSelectedAnswer(true)
        } else {
            setSelectedAnswer(false)
        }

    }
    const inputRef = useRef(null);
    const addLeadingZero = (number) => (number > 9 ? number : `${number}`)



    function markCourse() {
        setMarkLoading(true);
        API.get(`/mark_course_quiz_section?data=${props.data.uid}&is_complete=1&priority_index=0&watch_duration=0&score=${((result.correctAnswers/quiz.length)*100)}`)
            .then(response => {
                var datt = response.data.data;
                
                if(datt.course_complete==1){
                    toast.success('Your course complete successfully');
                    navigate(`/dashboard`)
                    return;
                }
                if (datt.uid != null) {
                    toast.success('Your lacture complete successfully');
                    if (datt.type == 0) {
                        navigate(`/lecture/${datt.uid}/${datt.slug}`)
                    } else if (datt.type == 1) {
                        navigate(`/course_quiz/${datt.uid}/${datt.slug}`)
                    }

                }
                setMarkLoading(false);
            }).catch((error) => {
                toast.error(error.response.data['message']);
            });
    }

    function markQuiz(qid) {
        API.get(`/mark_course_quiz?data=${qid}&answare=${q_ans}&question_index=${activeQuestion}`)
            .then(response => {
                if (response.data.data != "") {
                    
                }
            })
    }

    function retakQuiz() {
        setActiveQuestion(0)
        setShowResult(false)
        setResult({
            score: 0,
            correctAnswers: 0,
            wrongAnswers: 0,
        })
    }

    return (
        <>
            <h6>{props.data.title}</h6>
            <div className="col-md-12">
                {!showResult ? (
                    <div className="quiz">
                        <section className="examTestProtalnumber mt-5 mb-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between">
                                            <p className=" me-2 m-0">Question Number:<strong className="orangeRed fs-16"> {addLeadingZero(activeQuestion + 1)}</strong></p>
                                            <p className="me-2 m-0">Total Question:<strong className="orangeRed fs-16">{addLeadingZero(quiz.length)}</strong></p></div>
                                        <div className="divided position-relative">
                                            <div className="divided-light-white" />
                                            <div className="divided-black" style={{ width: '0%' }} /></div>
                                    </div></div></div></section>
                        <section className="examTestQuicks mb-3">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card bg-white shadow-lg py-3">
                                            <div className="card-body">
                                                <p>Q{addLeadingZero(Number(activeQuestion) + Number(1))}.{title}</p>
                                                <div className="text-center">
                                                    {questions.map((questionr, index1) => (
                                                        <h6 key={index1} className="mb-1 answer ">{questionr}</h6>
                                                       
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <form id="myForm">
                            <div className="container mb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className="mt-2 mb-2" for={`mark${activeQuestion}`}>Answer</h6>
                                        <div className="card bg-white shadow-lg border-0">
                                            <input ref={inputRef} id={`mark${activeQuestion}`} name={`mark${activeQuestion}`} onChange={(e) => onAnswerSelected(e.target.value, answare)} className="form-control form-control-lg shadow-lg" type="text" aria-label="default input example" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <section className="examTestAns mb-3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-between">
                                                <button type="button" className="btn btn-outline-dark"><i className="fas fa-arrow-left me-1" /> Go Back</button>
                                                <button type="submit" className="btn orangeRed-bg text-white" onClick={onClickNext}
                                                    disabled={selectedAnswerIndex === null}> {activeQuestion === quiz.length - 1 ? 'Finish' : 'Next'} <i className="fas fa-arrow-right ms-1" /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </form>
                    </div>
                ) : (
                    <div className="result">
                        <h3>Result</h3>
                        <p>
                            Total Question: <span>{quiz.length}</span>
                        </p>
                        <p>
                            Total Score:<span> {result.score}</span>
                        </p>
                        <p>
                            Correct Answers:<span> {result.correctAnswers}</span>
                        </p>
                        <p>
                            Wrong Answers:<span> {result.wrongAnswers}</span>
                        </p>
                        <div className="text-center">
                            <button onClick={() => retakQuiz()} className="btn btn-outline-info mb-2 "><i className="fas fa-arrow-left"></i> Retake Quiz</button> <span style={{ width: '10px' }}></span> <button onClick={() => markCourse()} className="btn btn-outline-warning mb-2">Complete & Continue <i className="fas fa-arrow-right"></i></button>

                        </div>
                    </div>
                )}

            </div>

        </>
    )
}