import { Fragment } from "react";
import ContentLoader, { Facebook, Code } from 'react-content-loader';
import { toast } from "react-toastify";
import API from "../../Network/API";
export default CourseBanner;
function CourseBanner({ data, load }) {

    const courseWishlist = (course_id) => {
        API.post(`/courseFav`, {
            'course_id': course_id
        }).then(response => {
            if (response.data.success === true) {
                toast.success('Add wishlist Successfully');
            }
        }).catch((error) => {
            toast.error('Add wishlist Unsuccessfully');
        });
    }

    const courseRemoveWishlist = (course_id) => {
        API.post(`/courseUnFavStore`, {
            'course_id': course_id
        }).then(response => {
            if (response.data.success === true) {
                toast.success('remove from wishlist Successfully');
            }
        }).catch((error) => {
            toast.error('remove wishlist Unsuccessfully');
        });
    }
    return (
        <Fragment>
            {load && <Code />}
            <div className="banner bg-black">
                <div className="container">
                    <div className="bannerCoursePadding">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="banner-text">
                                    <h4 className="bannerSubCourseTitle orangeRed">{data.category}</h4>
                                    <h1 className="bannerCourseTitle text-white">{data.name}</h1>
                                    <h4 className="bannerAuthorName text-white">Course by <a href="#">{data.teacher}</a></h4>
                                    <p className="bannerCourseDescription text-white">
                                        {data.short_details}
                                    </p>
                                    {/* <div className="rating">
                                        <span className="rating-count">{data.avg_rating}</span>
                                        <span className="rating-icon">
                                            <i className={`fas fa-star ${data.avg_rating > 0 && data.avg_rating < 2 ? '' : 'text-dark'} `}></i>
                                            <i className={`fas fa-star ${data.avg_rating > 1 && data.avg_rating < 3 ? '' : 'text-dark'} `}></i>
                                            <i className={`fas fa-star ${data.avg_rating > 2 && data.avg_rating < 4 ? '' : 'text-dark'} `}></i>
                                            <i className={`fas fa-star ${data.avg_rating > 3 && data.avg_rating < 5 ? '' : 'text-dark'} `}></i>
                                            <i className={`fas fa-star ${data.avg_rating >= 5 ? '' : 'text-dark'} `}></i>
                                        </span>
                                        {data.total_student > 0 ?
                                            <span className="total-rating">{data.total_student} students</span>
                                            : " "}
                                    </div> */}
                                    {data.is_fav==1?
                                        <button type="button" className="btn btn-outline-light btn-lg me-3" onClick={() => courseRemoveWishlist(data.id)}>Wishlist <i className="fa fa-heart ms-2 text-danger"></i> </button>
                                        :
                                        <button type="button" className="btn btn-outline-light btn-lg me-3" onClick={() => courseWishlist(data.id)}>Wishlist <i className="far fa-heart ms-2"></i> </button>
                                    }
                                    <button type="button" className="btn btn-outline-light btn-lg d-none">Share <i className="fas fa-share-alt ms-2"></i></button>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="banner-img d-flex justify-content-center align-items-center">
                                    <div className="product-slider">
                                        <div className="mb-0">
                                            {/* <a data-bs-toggle="modal" data-bs-target="#homeVideo" className="class-video p-0"> */}
                                                <img src={data.cover} alt="img" className="img-responsive  border rounded course-feature-img" />
                                                {/* <span className="video-icon" href="javascript:void(0)"><i className="fas fa-play orangeRed"></i></span> */}
                                            {/* </a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}