
import React, {Fragment, useEffect, useState} from "react";


import {Link, useParams} from "react-router-dom";
import {Code} from "react-content-loader";
import Pagination from "react-js-pagination";
import Nav from "../Component/Nav";
import TrainingLeftNav from "../Training/TrainingLeftNav";
import API from "../Network/API";

export default function ExamResultDetail() {

    const [practice_history, setPracticeHistories] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}]);
    const [practice_level, setPracticeLevel] = useState([]);
    const [practice_detail, setPracticeDetail] = useState([]);
    const [loading, setLoading] = useState(false);
    const [current_page, setCurrentPage] = useState([]);
    const [per_page, setPerPage] = useState([]);
    const [total_page, setTotalPage] = useState([]);
    let {id} = useParams();

    const fetchData = async (pageNumber = 1) => {
        setLoading(true);
        API.get(`/exam_details/${id}/?page=${pageNumber}`).then(response => {
            setPracticeLevel(response.data.data.lesson);
            setPracticeDetail(response.data.data.exam_participate);
            setPracticeHistories(response.data.data.data);
            setCurrentPage(response.data.data.current_page);
            setPerPage(response.data.data.per_page);
            setTotalPage(response.data.data.total_page);
            setLoading(false);
        })
    };

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <Fragment>
            <Nav/>
            <div className="studentDashboard">
                <div className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <TrainingLeftNav/>
                            </div>
                            <div className="col-12 col-sm-8 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                                <div className="StudentDashboardContent">
                                    <h4 className=""> Exam Details </h4>
                                    <div className="row mb-3">
                                        <div className="card border-danger">
                                            <div className="card-body">
                                                <div className="form-horizontal bold-label">
                                                    <div className="form-group row">
                                                        <label
                                                            className="col-form-label col-md-2 col-sm-4 fix-margin">Lesson
                                                            Name</label>
                                                        <div className="col-md-4 col-sm-8 fix-margin">
                                                            {practice_level.name}
                                                        </div>

                                                        <label
                                                            className="col-form-label col-md-2 col-sm-4 fix-margin">Total
                                                            Questions</label>
                                                        <div className="col-md-4 col-sm-8 fix-margin">
                                                            {practice_level.total_question}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label
                                                            className="col-form-label col-md-2 col-sm-4 fix-margin">Start
                                                            Date</label>
                                                        <div className="col-md-4 col-sm-8 fix-margin">
                                                            {practice_level.start_time}
                                                        </div>

                                                        <label
                                                            className="col-form-label col-md-2 col-sm-4 fix-margin">End
                                                            Date</label>
                                                        <div className="col-md-4 col-sm-8 fix-margin">
                                                            {practice_level.end_time}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label
                                                            className="col-form-label col-md-2 col-sm-4 fix-margin">Total
                                                            Solved Questions</label>
                                                        <div className="col-md-4 col-sm-8 fix-margin">
                                                            {practice_detail.total_question}
                                                        </div>

                                                        <label
                                                            className="col-form-label col-md-2 col-sm-4 fix-margin">Total
                                                            Correct</label>
                                                        <div className="col-md-4 col-sm-8 fix-margin">
                                                            {practice_detail.total_correct}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label
                                                            className="col-form-label col-md-2 col-sm-4 fix-margin">Total
                                                            In Correct</label>
                                                        <div className="col-md-4 col-sm-8 fix-margin">
                                                            {(practice_detail.total_question - practice_detail.total_correct)}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label
                                                            className="col-form-label col-md-2 col-sm-4 fix-margin">Correct
                                                            Answer Percentage</label>
                                                        <div className="col-md-4 col-sm-8 fix-margin">
                                                            {((practice_detail.total_correct / practice_detail.total_question) * 100).toFixed(2)} %
                                                        </div>

                                                        <label
                                                            className="col-form-label col-md-2 col-sm-4 fix-margin">In
                                                            Correct Answer Percentage</label>
                                                        <div className="col-md-4 col-sm-8 fix-margin">
                                                            {(((practice_detail.total_question - practice_detail.total_correct) / practice_detail.total_question) * 100).toFixed(2)} %
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <hr/>
                                                <div id="grid-view_wrapper"
                                                     className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    {loading && practice_history?.map(practice => (
                                                        <Code/>
                                                    ))}
                                                    {!loading ?
                                                        <table className={`table table-responsive table-bordered`}>
                                                            <thead>
                                                            <tr>
                                                                <th className="text-center">ID</th>
                                                                <th className="text-center">Question Text</th>
                                                                <th className="text-center">Correct Answer</th>
                                                                <th className="text-center">Your Answered</th>
                                                                <th className="text-center">Status</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {!loading && practice_history?.map((practice, index) => (
                                                                <tr>
                                                                    <th scope="row" className="text-center">{index}</th>
                                                                    <td>
                                                                        {practice.questions?.map(question_name => (
                                                                            <span>
                                                                      {question_name + " "}
                                                                   </span>
                                                                        ))}
                                                                    </td>
                                                                    <td className="text-center">{practice.q_answer}</td>
                                                                    <td className="text-center">{practice.answer}</td>
                                                                    <td className="text-center">{practice.is_correct==1?'Correct':'Incorrect'}</td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                        : " "}


                                                </div>
                                                <div className="row justify-content-start pb-20">
                                                    {total_page > 49 ?
                                                        <div>
                                                            <Pagination
                                                                activePage={current_page ? current_page : 0}
                                                                itemsCountPerPage={per_page ? per_page : 0}
                                                                totalItemsCount={total_page ? total_page : 0}
                                                                onChange={(pageNumber) => {
                                                                    fetchData(pageNumber)
                                                                }}
                                                                pageRangeDisplayed={8}
                                                                itemClass="page-item"
                                                                linkClass="page-link"
                                                                firstPageText="First Page"
                                                                lastPageText="Last Lage"
                                                            />
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}