import React, { Fragment, useEffect, useState, useRef } from 'react';
import API from "../Network/API";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useParams } from "react-router-dom";
import CountdownTimer from '../Props/CountdownTimer';
toast.configure();
export default function ExamBody({ questions, endTime, lesson }) {
    const [questionIndex, setQuestionIndex] = useState(null)
    const [answerStatus, setAnswerStatus] = useState(null)
    const [correctAnswerCount, setCorrectAnswerCount] = useState(0)
    const [back_count, setBackCount] = useState(0)
    const [quizComplete, setQuizComplete] = useState(false)
    const [questionID, setQuestionID] = useState(null)
    const [questionAnswer, setQuestionAnswer] = useState("")
    const [last_quiz, setQuiz] = useState([]);
    const [exam_end, setExamOver] = useState(false);
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const textData = useRef();

    const [timerOut, setTimerOut] = useState([]);
    // const [endTime] = useState(moment().add({ minutes: timerQuiz }));
    var countDownDate = Number(endTime);
    //var countDownDate = endTime;
    const [quizStarted, setQuizStarted] = useState(false);
    const [quiz_run, setQuizRun] = useState(false);

    const [seconds, setSeconds] = useState(lesson?.duration);

    useEffect(() => {

        if (quizStarted==true) {

            if (Number(seconds) > 0) {
                const timerId = setTimeout(() => {
                    setSeconds(seconds - 1);
                    // console.log("Time:" + seconds);
                }, 1000);


                return () => clearTimeout(timerId);
            } else {
                // console.log("iso over:" + seconds);
                if (seconds == 0) {
                     handleAutoSubmit()
                }
                //  handleAutoSubmit()
            }

        }
        console.log("Count:" + seconds);
    }, [seconds, quizStarted]);


    
    const formatTime = (totalSeconds) => {
        const hrs = Math.floor(totalSeconds / 3600);
        const mins = Math.floor((totalSeconds % 3600) / 60);
        const secs = totalSeconds % 60;

        return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    }

    const checkExamStatus = () => {
        setLoading(true);
        API.get(`/get_last_json_exam/${id}`).then(response => {
            setLoading(false);

                if (response.data.data.total_answer == response.data.data.total_question) {
                    setQuizRun(true);
                    setQuizStarted(true);
                    setQuizComplete(true);
                }
            
        }).catch((error) => {
            setLoading(false);
        });
        
    }

    const checkExam = () => {
        setLoading(true);
        API.get(`/get_last_json_exam/${id}`).then(response => {
            setQuiz(response.data.data);
            //console.log(response.data.data);
            if (response.data.data) {
                setCorrectAnswerCount(response.data.data.total_answer);
                if (questionIndex == null) {
                    if (response.data.data.total_answer == response.data.data.total_question) {
                        setQuizComplete(true)

                    } else {

                        if (questions.length > 0 && response.data.data.question_index < questions.length) {
                            setQuestionIndex(response.data.data.question_index + 1)
                        } else {
                            setQuizComplete(true)
                        }

                    }
                    setLoading(false);
                }
            }
        }).catch((error) => {
            setLoading(false);
        });
        setAnswerStatus(null)
    }

    useEffect(() => {
        checkExamStatus();
    }, [])

    useEffect(() => {
    }, [questionIndex])

    useEffect(() => {
        if (answerStatus) {
            setCorrectAnswerCount(count => count + 1)
        }
    }, [answerStatus])


    const onNextClick = () => {
        if (questionIndex === questions.length - 1) {
            setQuizComplete(true)
        } else {
            setQuestionIndex(questionIndex == null ? 0 : questionIndex + 1)
        }
        setBackCount(0)
    }



    const onBackClick = () => {

        if (back_count > 0) {
            return 0;
        }
        setQuestionIndex(questionIndex - 1)
        setBackCount(count => count + 1)

    }

    const onRestartClick = () => {
        setQuizComplete(false)
        setQuestionIndex(null)
        setCorrectAnswerCount(0)
        setBackCount(0)
    }

    const onRestart = () => {
        setQuestionAnswer(null);
    }

    const handleAutoSubmit = () => {

        var data = {
            'lesson_id': lesson?.id,
        }
        API.post('json_exam_auto_submit', data).then(response => {
            setQuizComplete(true)
        }).catch((error) => {
            toast.error('Something missing.Please try again.');
        });

        document.getElementById("myForm").reset();

    }

    const handleSubmit = (event) => {
        event.preventDefault();

        var data = {
            'question_id': questionID,
            'answer': questionAnswer,
            'question_index': questionIndex,
        }
        if (questionAnswer == "") {
            toast.error('The answer field was required');
            return false;
        }
        if (questionID == "") {
            return false;
        }
        API.post('json_exam_submit', data, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then(response => {
            if (response.data.success === true) {
                setCorrectAnswerCount(count => count + 1)
            }
        }).catch((error) => {
            toast.error('Something missing.Please try again.');
        });

        document.getElementById("myForm").reset();
        textData.current.focus();
        onNextClick();
        setBackCount(0);
        // console.log(data);
    }

    const startQuiz = () => {
        setQuizStarted(true);
        // console.log("quiz start");
        onNextClick()
    };

    const runQuiz = () => {
        setQuizRun(true);
        checkExam();
    };


    if (!quiz_run) {
        return (
            <div className="p-5">
                <h1>Exam Summary</h1>
                <p><b>Lesson:</b> {lesson.name} {lesson?.id}</p>
                <p><b>Total Question:</b> {lesson.total_question}</p>
                <p><b>Start Time:</b> {lesson.start_time}</p>
                <p><b>End Time:</b> {lesson.end_time}</p>
                <p><b>Total Time:</b> {lesson.duration / 60} Minutes</p>
                <button className="btn btn-sm btn-success" onClick={runQuiz}>Start Exam</button>
            </div>
        )
    }

    // if (!loading && questions[questionIndex] === undefined) {
    //     return (
    //         <Fragment>

    //         </Fragment>
    //     );
    // }

    return (
        <>
            {quizStarted == true && quizComplete==false ?
                <section className="examTestProtaltime mt-2">
                    <div className="bg-black py-4 mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between">
                                        <h6 className="text-white m-0">Exam Board </h6>
                                        <p className="text-white me-2 m-0">
                                            <span className="orangeRed">{formatTime(seconds)}</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section> : ''}

            <div className="quiz">

                {!quizStarted && quiz_run == true ?
                    <>
                        <CountdownTimer initialSeconds={5} onCountdownEnd={startQuiz} />
                    </>

                    : ''}

                {quizStarted && quizComplete ? (
                    <Fragment>
                        <h1>Exam complete!</h1>
                        <p>You answered {questions.length} questions (out of a
                            total {questions.length} questions)</p>
                        <Link to={`${process.env.PUBLIC_URL}/exam/category`} className="btn btn-sm btn-success mr-2" >Back Home</Link> 
                        <Link to={`${process.env.PUBLIC_URL}/exam/history/details/${lesson.id}`} className="btn btn-sm btn-info ml-2" style={{marginLeft:'10px'}} >See Result</Link>
                    </Fragment>
                ) : (quizStarted == true && quizComplete == false) ? (
                    <Fragment>
                        <ProgressBar currentQuestionIndex={questionIndex} totalQuestionsCount={questions.length} />
                        <Question
                            question={questions[questionIndex]}
                            setAnswerStatus={setAnswerStatus}
                        />
                        <form onSubmit={handleSubmit} id="myForm">
                            <div className="container mb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className="mt-2 mb-2">Answer</h6>
                                        <div className="card bg-white shadow-lg border-0">

                                            <input ref={textData} onChange={(e) => setQuestionAnswer(e.target.value)}
                                                className="form-control form-control-lg shadow-lg" type="text"
                                                placeholder="" aria-label="default input example" />

                                        </div>
                                        <input ref={(e) => setQuestionID(questions[questionIndex]?.id)} type="hidden" />

                                    </div>
                                </div>
                            </div>
                            <section className="examTestAns mb-3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-between">
                                                {questionIndex > 0 ?
                                                    <button type="button" onClick={onBackClick}
                                                        className="btn btn-outline-dark"><i
                                                            className="fas fa-angle-left me-1"></i> Go Back
                                                    </button>
                                                    : ""
                                                }

                                                <button type="submit"
                                                    className="btn orangeRed-bg text-white">{questionIndex === questions.length - 1 ? "See results of this exam" : "Next Question ->"}
                                                    <i className="fas fa-angle-right ms-1"></i></button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </form>

                        {/*{answerStatus != null && (*/}
                        {/*    <div>*/}
                        {/*        <div*/}
                        {/*            className="answerStatus">{!!answerStatus ? "Correct! :)" : "Your answer was incorrect :("}</div>*/}
                        {/*        <button className="next" onClick={onNextClick}>*/}
                        {/*            {questionIndex === questions.length - 1 ? "See results of this quiz" : "Next Question ->"}*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </Fragment>
                ) : ''}

                {/*{questionIndex != null && <button className="restart" onClick={onRestartClick}>Restart quiz</button>}*/}
            </div>
        </>
    );
}

const Question = ({ question, setAnswerStatus }) => {
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null)

    useEffect(() => {
        if (selectedAnswerIndex != null) {
            setAnswerStatus(selectedAnswerIndex === question.correctAnswerIndex)
        }
    }, [selectedAnswerIndex])

    useEffect(() => {
        setSelectedAnswerIndex(null)
    }, [question])

    const getClasses = (index) => {
        let classes = []
        if (selectedAnswerIndex != null) {
            if (selectedAnswerIndex === index) {
                classes.push("selected")
            }
            if (index === question.correctAnswerIndex) {
                if (selectedAnswerIndex === index) {
                    classes.push("correct")
                } else {
                    classes.push("incorrect")
                }
            }
        }

        return classes.join(" ")
    }

    return (
        <section className="examTestQuicks mb-3">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card bg-white shadow-lg py-3">
                            <div className="card-body">
                                <p>{question?.question}</p>
                                <div className="text-center">
                                    {question?.answers.map((answer, index) => {
                                        return <h6 key={index}
                                            className={`mb-1 answer ${getClasses(index)}`}>{answer}</h6>

                                    })}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </section>
        // <div className="question">
        //     <div className="questionText">{question.question}</div>
        //     <div className="answers">
        //         {question.answers.map((answer, index) => {
        //             return <div key={index} className={`answer ${getClasses(index)}`}
        //                         onClick={() => selectedAnswerIndex == null && setSelectedAnswerIndex(index)}>{answer}</div>
        //         })}
        //     </div>
        // </div>
    )
}

const ProgressBar = ({ currentQuestionIndex, totalQuestionsCount }) => {
    const progressPercentage = (currentQuestionIndex / totalQuestionsCount) * 100

    return <section className="examTestProtalnumber mt-5 mb-4">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-between">
                        <p className=" me-2 m-0">Question Number:
                            <strong className="orangeRed fs-16"> {currentQuestionIndex}</strong>
                        </p>
                        <p className="me-2 m-0">Total Question:
                            <strong className="orangeRed fs-16"> {totalQuestionsCount}</strong>
                        </p>
                    </div>
                    <div className="divided position-relative">
                        <div className="divided-light-white"></div>
                        <div className="divided-black" style={{ width: `${progressPercentage}%` }}></div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="progressBar">
        <div className="text">{currentQuestionIndex} answered ({totalQuestionsCount - currentQuestionIndex} remaining)</div>
        <div className="inner" style={{ width: `${progressPercentage}%` }} />
     </div> */}
    </section>

}