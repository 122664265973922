import { Link } from "react-router-dom";

function Course({ data }){

    return (
        <div className="col-10 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 pb-3" loading="lazy">
            <div className="card course-card shadow rounded">
            <Link to={`/course/${data.id}/${data.slug}`}> <img src={data.cover} className="card-img-top" loading="lazy" alt="..."/> </Link>
                    <div className="card-body">
                    <Link to={`/course/${data.id}/${data.slug}`}><h5 className="course-title">{data.name}</h5></Link>
                        <p className="author-name text-bold">#{data.category}</p>
                        <p className="author-name">Course by {data.teacher}</p>
                        <p className="course-description">{data.short_details}</p>
                        {/* <div className="rating">
                            <span className="rating-count">{data.avg_rating}</span>
                            <span className="rating-icon">
                            <i className={`fas fa-star ${data.avg_rating>0?'':'text-dark'} `}></i>
                                    <i className={`fas fa-star ${data.avg_rating>1?'':'text-dark'} `}></i>
                                    <i className={`fas fa-star ${data.avg_rating>2?'':'text-dark'} `}></i>
                                    <i className={`fas fa-star ${data.avg_rating>3?'':'text-dark'} `}></i>
                                    <i className={`fas fa-star ${data.avg_rating>=5?'':'text-dark'} `}></i>
                                </span>
                            <span className="total-rating">{data.total_rating}</span>
                        </div> */}
                        <Link to={`/course/${data.id}/${data.slug}`} className="btn btn-dark custom-btn w-100">{data.currency_code} {data.course_price}</Link>
                    </div>
            </div>
        </div>
    );
}
export default Course