import React, { Component, Fragment, props, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import API from "../../Network/API";
import '../../index.css';
export default CourseContent;
function CourseContent({ data, course_sections, course_id, course_slug }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lecture, setLecture] = useState([]);
    const [lecture_type, setLectureType] = useState([]);
    function onOpenModal(video_url, type) {
        setLoading(true);
        setLecture(video_url);
        setLectureType(type);
        setOpen(true);
        setLoading(false);
        // API.get(`/lecture/${id}`).then(response => {
        //     setLecture(response.data.data.lecture);
        //     setLoading(false);
        //     setOpen(true);
        // })
    }

    const onCloseModal = () => setOpen(false);

    return (
        <Fragment>
            <div className="courseContentSection mt-42">
                <h1 className="courseDetailsHeading">Course Content</h1>
                <div className="courseContentSubHeading">
                    <span className="me-1">•</span>  <span className="">{data.count_sections} Sections</span>  <span className="me-1 ms-1">•</span>  <span>{data.count_lacture} Lectures</span>  <span className="me-1 ms-1">•</span>  <span>{data.total_duration} Total length</span>
                </div>
                <div className="card bg-light-white">
                    <div className="courseContentAccordion">
                        <div className="accordion bg-light-white" id="accordionExample">
                            {course_sections?.map((course_section, i) => (
                                <div>
                                    {course_section.total_lecture > 0 ?
                                        <div key={i} className="accordion-item bg-light-white">
                                            <div className="accordion-header" id="headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${course_section.id}`} aria-expanded="false" aria-controls="collapseOne">
                                                    {course_section.title} <span className="lecturesHour"><span className="me-1">•</span>{course_section.total_lecture}  Lectures<span className="me-1 ms-1">•</span>{course_section.total_hour}</span>
                                                </button>
                                            </div>
                                            <div id={`collapseOne${course_section.id}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" >
                                                <div className="accordion-body bg-white">
                                                    <ul className="courseVideoListing">
                                                        <>
                                                            {course_section?.sub_sections?.map((section, k) => (
                                                                    <>
                                                                    {section?.lectures.length>0?
                                                                <div key={k} className="accordion-item bg-light-white">
                                                                    <div className="accordion-header" id={`headingOne${section.id}`}>
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOneSec${section.id}`} aria-expanded="false" aria-controls="collapseOneSec">
                                                                            {section.title} 
                                                                        </button>
                                                                    </div>
                                                                    <div id={`collapseOneSec${section.id}`} className="accordion-collapse collapse" aria-labelledby={`headingOne${section.id}`} data-bs-parent="#accordionExample1" >
                                                                        <div className="accordion-body bg-white">
                                                                            <ul className="courseVideoListing">
                                                                                {section?.lectures?.map((lecture, k) => (
                                                                                    <>
                                                                                        {lecture.is_paid == 0 ?
                                                                                            <li><a href='javascript:void(0)' onClick={() => onOpenModal(lecture.upload_type == 2 ? lecture.video_url : lecture.video_attachment, lecture.upload_type)}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3 text-success`}></i>{lecture.title}</a></li>
                                                                                            :
                                                                                            <li><Link to={`${data.is_purchased == 1 && lecture.type == 0 ? `${process.env.PUBLIC_URL}/lecture/${lecture.uid}/${lecture.slug}` : data.is_purchased == 1 && lecture.type == 1 ? `${process.env.PUBLIC_URL}/course_quiz/${lecture.uid}/${lecture.slug}` : ""}`} className={`text-sm ${data.is_purchased == 1 ? `text-dark` : 'text-gray-500'}`}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3`}></i>{lecture.title}</Link></li>
                                                                                        }
                                                                                    </>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                                :''}
                                                                </>

                                                            ))}
                                                        </>
                                                        {course_section?.lecture?.map((lecture, i) => (

                                                            <div>


                                                                {lecture.is_paid == 0 ?
                                                                    <li><a href='javascript:void(0)' onClick={() => onOpenModal(lecture.upload_type == 2 ? lecture.video_url : lecture.video_attachment, lecture.upload_type)}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3 text-success`}></i>{lecture.title}</a></li>
                                                                    :
                                                                    <li><Link to={`${data.is_purchased == 1 && lecture.type == 0 ? `${process.env.PUBLIC_URL}/lecture/${lecture.uid}/${lecture.slug}` : data.is_purchased == 1 && lecture.type == 1 ? `${process.env.PUBLIC_URL}/course_quiz/${lecture.uid}/${lecture.slug}` : ""}`} className={`text-sm ${data.is_purchased == 1 ? `text-dark` : 'text-gray-500'}`}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3`}></i>{lecture.title}</Link></li>
                                                                }
                                                            </div>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        : ""}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <Modal open={open} onClose={onCloseModal} center>
                {!loading && lecture_type == 2 ? <iframe title="YouTube video player" width="640" height="390" src={`${lecture}?autoplay=1`} frameBorder='0' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={1} allowTransparency={1}></iframe>
                    :
                    <video controls autoPlay={1} width="100%" height="415" controls src={lecture} type="video/mp4">
                    </video>
                }
            </Modal>
        </Fragment>
    );
}