import React, {Component, Fragment, props, useState, useEffect} from "react";
import Course from "../Props/Course";
import API from "../Network/API";
import { Link } from "react-router-dom";
import ContentLoader, { Facebook } from 'react-content-loader'
import CoursePlaceholder from "../Network/CoursePlaceholder";
function CourseHome() {
    const [courses,setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        API.get(`/home_courses`).then(response => {
            setCourses(response.data.data);
            setLoading(false);
        })
       
    }, []);

    return (
        <div  className="course gradient-bg">
            <div className="container">
                <div className="section-padding pb-20 pt-60">
                    <div className="row  justify-content-center">
                        <div className="col-md-6 col-sm-8">
                            <div className="section-heading text-center pb-20 pt-20">
                                <h1 className="title">Featured Courses</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                    {loading &&<><CoursePlaceholder /><CoursePlaceholder /><CoursePlaceholder /></>}
                        {!loading && courses.map(course => (
                        <Course  key={course.id} data={course} />
                        ))}
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center mt-5 pb-40">
                            <Link to="/courses" className="btn custom-btn btn-outline-dark ">Explore All</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CourseHome