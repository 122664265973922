import React, {Fragment, useState, useEffect} from "react";
import API from "../Network/API";
import Nav from "./Nav";
import Footer from "./Footer";
import CourseBanner from "../Page/CourseDetails/CourseBanner";
import CourseDetail from "../Page/CourseDetails/CourseDetail";
import {useParams,useLocation} from "react-router-dom";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const useQuery= () => {
  return new URLSearchParams(useLocation().search);
}
function CourseDetails() {
  const [course,setCourse] = useState([]);
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  let query = useQuery();

  useEffect(() => {
    setLoading(true);
    if (localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined') {
      API.get(`/private_course/${id}`).then(response => {
        setCourse(response.data.data);
        setLoading(false);
      })
    }else{
      API.get(`/course/${id}`).then(response => {
        setCourse(response.data.data);
        setLoading(false);
      })
    }
    if(query.get('payment_status')&&query.get('payment_status')==="Success"){
      toast.success('Purchased Successfully.Please check your mail or dashboard');
    }else if(query.get('payment_status')&&query.get('payment_status')!=='Success'){
      toast.error('Transaction declined.Please try again later.');
    }

  }, []);

  
    return (
    <Fragment>
    <Nav/>
   
   <CourseBanner key='1' data={course} load={loading}/>
   <CourseDetail key='2' data={course} load={loading}/>
    <Footer/>
      </Fragment>
      );
}
export default CourseDetails;