import React, { Component, Fragment, props, useState, useEffect } from "react";
import Footer from "./Footer";
import Nav from "./Nav";
import API from "../Network/API";
import { BrowserRouter as Router, Redirect, Switch, Route, Link, useParams } from "react-router-dom";
import CardDetails from "../Page/Checkout/CardDetails";
import CartSummery from "../Page/Checkout/CartSummery";

export default function Checkout() {
    const [course, setCourse] = useState([]);
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        API.get(`/course/${id}`).then(response => {
            setCourse(response.data.data);
            setLoading(false);
        })

        API.get(`/check_user`).then(response => {
            if (response.data.success === true) {
                
            }else{
                localStorage.setItem("isLoggedIn", false);
                localStorage.setItem("token", null);
                window.location.href = "/login";
            }
        }).catch((error) => {
          
        });


    }, []);
    return (
        <Fragment>
            <Nav />
            <div className="courseList">
                <div className="courseListPadding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-7 col-lg-9 col-xl-9 col-xxl-9">
                                <div className="checkOutPage">
                                    <h2 className="orangeRed">Checkout</h2>
                                    <h6 className>Select Payment Method</h6>

                                    <CardDetails course={course} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-5 col-lg-3 col-xl-3 col-xxl-3">
                                <CartSummery data={course} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    );
}