import React, {Fragment, useEffect, useState} from "react";
import ContentLoader, {Facebook, Code} from 'react-content-loader';
import {Link} from "react-router-dom";
import API from "../Network/API";
import Nav from "../Component/Nav";
import TrainingLeftNav from "../Training/TrainingLeftNav";
import Footer from "../Component/Footer";
import Pagination from "react-js-pagination";

export default function ExamHistory() {
    const [practice_histories, setPracticeHistories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [current_page, setCurrentPage] = useState([]);
    const [per_page, setPerPage] = useState([]);
    const [total_page, setTotalPage] = useState([]);
    const fetchData= () =>{
        setLoading(true);
        API.get(`/exam_history`).then(response => {
            setPracticeHistories(response.data.data.data);
            setCurrentPage(response.data.data.current_page);
            setPerPage(response.data.data.per_page);
            setTotalPage(response.data.data.total_page);
            setLoading(false);
        })
    }
    useEffect(() => {
        fetchData();
    }, [])

      
    const formatTime = (totalSeconds) => {
        const hrs = Math.floor(totalSeconds / 3600);
        const mins = Math.floor((totalSeconds % 3600) / 60);
        const secs = totalSeconds % 60;

        return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    }

    return (
        <Fragment>
            <Nav/>
            <div className="studentDashboard">
                <div className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <TrainingLeftNav/>
                            </div>
                            <div className="col-12 col-sm-8 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                                <div className="StudentDashboardContent">
                                    <h4 className=""> Exam History </h4>
                                    <div className="row mb-3">
                                        <div className="rounded">
                                            <div className="table-responsive">
                                               
                                                    <table className="table">
                                                        <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Level Name</th>
                                                            <th>Lesson Name</th>
                                                            <th>Total Questions</th>
                                                            <th>Total Correct</th>
                                                            <th>Total Incorrect</th>
                                                            <th>Total Duration</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {!loading && practice_histories?.length>0 && practice_histories?.map(practice => (
                                                            <tr>
                                                                <th scope="row">{practice.id}</th>
                                                                <td>{practice.level_name}</td>
                                                                <td>{practice.lesson_name}</td>
                                                                <td>{ practice.total_question }</td>
                                                                <td>{ practice.total_correct }</td>
                                                                <td>{ practice.total_incorrect }</td>
                                                                <td>{ formatTime(practice.total_duration) }</td>
                                                                <td>
                                                                    <Link to={`/exam/history/details/${practice.lesson_id}`} className={`btn btn-sm btn-success`}>Details</Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                            </div>
                                            <div className="row justify-content-start pb-20">
                                                    {total_page > 9 ?
                                                        <div>
                                                            <Pagination
                                                                activePage={current_page ? current_page : 0}
                                                                itemsCountPerPage={per_page ? per_page : 0}
                                                                totalItemsCount={total_page ? total_page : 0}
                                                                onChange={(pageNumber) => {
                                                                    fetchData(pageNumber)
                                                                }}
                                                                pageRangeDisplayed={8}
                                                                itemClass="page-item"
                                                                linkClass="page-link"
                                                                firstPageText="First Page"
                                                                lastPageText="Last Lage"
                                                            />
                                                        </div>
                                                        : ''}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </Fragment>
    );
}