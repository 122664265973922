import React, {Fragment, useEffect, useState} from 'react';
import Pagination from "react-js-pagination";
import API from "../Network/API";
import Nav from "../Component/Nav";
import Footer from "../Component/Footer";
import SideBar from "./partials/SideBar";
import {Link} from "react-router-dom";

import ContentLoader, { Facebook } from 'react-content-loader'
export default MyWishlist;

function MyWishlist() {
    const [courses, setCourse] = useState([]);
    const [current_page, setCurrentPage] = useState([]);
    const [per_page, setPerPage] = useState([]);
    const [total_page, setTotalPage] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async (pageNumber = 1) => {
        setLoading(true);
        API.get(`/my_wishlist?page=${pageNumber}`).then(response => {
            setCourse(response.data.data.courses);
            setCurrentPage(response.data.data.current_page);
            setPerPage(response.data.data.per_page);
            setTotalPage(response.data.data.total_page);
            setLoading(false);
        })
    };
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <Fragment>
            <Nav/>
            <div className="studentDashboard">
                <div className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="DashboardSideBar ">
                                    <SideBar/>
                                </div>
                            </div>
                            <div className="col-12 col-sm-8 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                                <div className="StudentDashboardContent">
                                    <h4 className>My Wishlist</h4>
                                    <div className="row mb-3">
                                        <div>
                                        {loading &&<><ContentLoader /></>}
                                        
                                            <table className='table table-bordered'>
                                                <thead>
                                                <tr>
                                                    <th>Course ID</th>
                                                    <th>Course Name</th>
                                                    <th>Time</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                              
                                                {!loading && courses?.map((course, index) => (
                                                    <tr key={course?.id}>
                                                        <td>{course?.id}</td>
                                                        <td><Link to={`/course/${course.id}/${course.course_slug}`} className="btn-link">{course?.course_name}</Link></td>
                                                    
                                                        <td>{course?.created_at}</td>
                                                        <td></td>

                                                    </tr>
                                                ))}

                                                </tbody>
                                            </table>
                                            {total_page>10?
                                            <div>
                                                <Pagination
                                                    activePage={current_page ? current_page : 0}
                                                    itemsCountPerPage={per_page ? per_page : 0}
                                                    totalItemsCount={total_page ? total_page : 0}
                                                    onChange={(pageNumber) => {
                                                        fetchData(pageNumber)
                                                    }}
                                                    pageRangeDisplayed={8}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    firstPageText="First Page"
                                                    lastPageText="Last Lage"
                                                />
                                            </div>
                                            :""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
}