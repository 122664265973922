import React, {Fragment, useEffect, useState} from "react";
import Nav from "../../Component/Nav";
import Footer from "../../Component/Footer";
import TrainingLeftNav from "../TrainingLeftNav";
import CoursePlaceholder from "../../Network/CoursePlaceholder";
import {Link, useParams} from "react-router-dom";
import API from "../../Network/API";
import NoticeBoard from "../NoticeBoard";
import ContentLoader from "react-content-loader";

export default function Lesson() {
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    let { id } = useParams();
    useEffect(() => {

        setLoading(true);
        API.get(`/training_level_lesson/${id}`).then(response => {
            setCategories(response.data.data);
            setLoading(false);
        })

    }, []);
    return (
        <Fragment>
            <Nav/>
            <div className="studentDashboard">
                <div className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <TrainingLeftNav/>
                            </div>
                            <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="StudentDashboardContent">
                                    <h4 className=""> Lessons </h4>
                                    <div className="row mb-3">
                                        {loading && [1,2,3,4]?.map(category => (
                                            <div className="col-4">

                                                <ContentLoader
                                                    speed={2}
                                                    width='100%'
                                                    height={200}
                                                    viewBox="0 0 100% 200"
                                                    backgroundColor="#f3f3f3"
                                                    foregroundColor="#ecebeb"
                                                >
                                                    <rect x="24" y="47" rx="0" ry="0" width="100%" height="100%"/>
                                                </ContentLoader>

                                            </div>
                                        ))}

                                        {!loading && categories?.length==0?
                                    <>
                                    <div className="col-12  text-center">
                                    <img src="/assets/image/notfound.png"/>
                                    </div>
                                    </> 
                                    :''   
                                    }

                                        {!loading && categories?.map(category => (
                                            <div className="col-4">
                                                <Link to={`/student-quiz/${category.uuid}`}>
                                                    <div
                                                        className="card gradientSoftBrand-bg mb-3 shadow-sm lessonWidagetmaxHeight">
                                                        <div className="card-body">
                                                            <h6 className="mb-2">{category.name}</h6>
                                                            <p className="mb-0">Total Questions:{category.total_question}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}



                                    </div>


                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <NoticeBoard/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
}