import { Fragment } from "react";
import CourseContent from "./CourseContent";
import CourseFeedback from "./CourseFeedback";
import CourseRequirment from "./CourseRequirment";
import Details from "./Details";
import ContentLoader, { Code  } from 'react-content-loader';
import { Link, useSearchParams } from "react-router-dom";
export default CourseDetail;
function CourseDetail({ data,load }){
    const self_data = JSON.parse(localStorage.getItem("user_data"));
    const [searchParams, setSearchParams] = useSearchParams();
    // console.log(searchParams.get("referral"))
    if(searchParams.get("referral")!=null){
        localStorage.setItem("referral_code",searchParams.get("referral"))
        localStorage.setItem("url", window.location.href);
    }
    
    return (
        <Fragment>
             {load && <Code  />}
            <div class="courseDetails">
    <div class="courseDetailsPadding">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-7 col-lg-9 col-xl-9 col-xxl-9">
                    <div class="learningSection">
                        <div class="card bg-light-white">
                            <div class="learningPadding">
                                <h1 class="courseDetailsHeading">What You’ll Learn</h1>
                                <div class="learningDec">
                                    <p style={{'white-space': 'pre-wrap'}}>{data.about}</p>
                                </div>
                            </div>                                                                      
                        </div>
                    </div>

                    <CourseContent data={data} course_id={data.id} course_slug={data.slug} course_sections={data.course_sections}/>
                    <CourseRequirment requirement={data.requirements}/>
                    <Details description={data.description}/>

                </div>
                <div class="col-12 col-sm-6 col-md-5 col-lg-3 col-xl-3 col-xxl-3">
                    <div class="coursePriceSection">
                        <div class="card bg-light-white">
                            <div class="card-body">
                                <h4 class="courseDetailsHeading text-center">{data.currency_code}{data.course_price}</h4>
                                {data.is_purchased!==1?
                                <Link to={`${process.env.PUBLIC_URL}/checkout/${data.id}/${data.slug}`} class="btn btn-lg w-100 btn-reg">
                                    Get this course
                                </Link>
                               :"" }
                                <p class="text-center mt-2">30 day money back guarantee</p>
                                {self_data?.affiliate_code!=null?
                                <button onClick={() => {navigator.clipboard.writeText(`${window.location.origin.toString()}/course/${data.id}/${data.slug}?referral=${self_data.affiliate_code}`)}}  class="btn btn-sm w-100 btn-reg">
                                Get Referral Link
                            </button>
                                :
                                ''
                            }
                                <div className="courseIncludeItems">
                                    <strong>This course includes:</strong>
                                    <ul className="courseIncludesList">
                                        <li><i className="fas fa-star me-2"></i>{data.total_duration} hours on-demand video</li>
                                        <li><i className="fas fa-file me-2"></i>{data.count_sections} lessons</li>
                                        <li><i className="fas fa-user-circle me-2"></i>Lifetime access</li>
                                        <li><i className="fas fa-mobile me-2"></i>Access on mobile and PC</li>
                                        <li><i className="fas fa-certificate me-2"></i>Certificate of completion</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        </Fragment>
    );
}