import React, {Fragment, useEffect, useState} from "react";
import Nav from "../../Component/Nav";
import Footer from "../../Component/Footer";
import TrainingLeftNav from "../../Training/TrainingLeftNav";
import CoursePlaceholder from "../../Network/CoursePlaceholder";
import {Link, useParams} from "react-router-dom";
import API from "../../Network/API";
import NoticeBoard from "../../Training/NoticeBoard";
import ContentLoader from "react-content-loader";

export default function ExamLesson() {
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState([]);
    const [level, setLevel] = useState([]);
    const [lessons, setLessons] = useState([]);
    let { id } = useParams();
    useEffect(() => {

        setLoading(true);
        API.get(`/json_exam_level_lesson/${id}`).then(response => {
            setLessons(response.data.data.lesson);
            setLevel(response.data.data.level);
            setCategory(response.data.data.category);
            setLoading(false);
        })

    }, []);
    return (
        <Fragment>
            <Nav/>
            <div className="studentDashboard">
                <div className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <TrainingLeftNav/>
                            </div>
                            <div className="col-12 col-sm-8 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                                <div className="StudentDashboardContent">
                                    <h4 className=""> Lessons </h4>
                                    <p>Exam > <Link to={`/exam/level/${category.uuid}`}>{category?.name}</Link> > {level?.name}</p>
                                    <div className="row mb-3">
                                        {loading && [1,2,3]?.map(category => (
                                            <div className="col-md-4">

                                                <ContentLoader
                                                    speed={2}
                                                    width='100%'
                                                    height={150}
                                                    viewBox="0 0 100% 150"
                                                    backgroundColor="#f3f3f3"
                                                    foregroundColor="#ecebeb"
                                                >
                                                    <rect x="24" y="47" rx="0" ry="0" width="100%" height="100%"/>
                                                </ContentLoader>

                                            </div>
                                        ))}
                                        
                                        {!loading && lessons?.length==0? <> <div className="col-12  text-center"> <img src="/assets/image/notfound.png"/> </div> </> :'' }

                                        {!loading && lessons?.map(category => (
                                            <div className="col-4">
                                                <Link to={`/student-exam/${category.uuid}`}>
                                                    <div
                                                        className="card gradientSoftBrand-bg mb-3 shadow-sm lessonWidagetmaxHeight">
                                                        <div className="card-body">
                                                            <h6 className="mb-2">{category.name}</h6>
                                                            <p className="mb-0">Total Questions: {category.total_question}</p>
                                                            <p className="mb-0">End Time: <samp style={{fontSize:'12px'}}>{category.end_time}</samp></p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}



                                    </div>


                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
}