import API from "../Network/API";
import React, { Component, Fragment, props, useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Footer() {
    const [loading, setLoading] = useState(false);
    const [page_loading, setPageLoading] = useState(false);
    const [resource_loading, setResourceLoading] = useState(false);
    const [web, setWeb] = useState([]);
    const [pages, setPage] = useState([]);
    useEffect(() => {
        API.get(`/web`).then(response => {
            setWeb(response.data.data);
            setLoading(false);
        })

        API.get(`/page`).then(response => {
            setPage(response.data.data);
            setPageLoading(false);
        })

       

    }, []);
  
    return (
        <div className="footer bg-black">
            <div className="container">
                <div className="footer-top">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="footer-contact">
                                <p className="location">{!loading?web.title:''}<br />
                                {!loading?web.address:''}</p>
                                <a href={`tel:${web.phone}`}><p className="number"><i className="fas fa-phone me-2"></i>{!loading?web.phone:''}</p></a>
                                <p className="email"><i className="fas fa-envelope me-2"></i>{!loading?web.email:''}</p>
                            </div>
                        </div>
                        <div className="col-1 d-none d-md-block">
                            <div className="vertical-separator"></div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="footer-heading">
                                <h6>Why Smart Brain?</h6>
                            </div>
                            <div className="footer-list">
                                <ul className="text-white">
                                {!page_loading && pages.length>0 && pages?.map(page => (
                                    <li><Link to={`/page/${page.id}/${page.slug}`}>{page.title}</Link></li>
                                 
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-1 d-none d-md-block">
                            <div className="vertical-separator"></div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="footer-list">
                                <ul className="text-white">
                                    {/* <li><a href="">Training</a></li>
                                    <li><a href="">Quiz Test</a></li>
                                    <li><a href="">Quiz Test</a></li>
                                    <li><a href="">Blog</a></li>
                                    <li><a href="">Contacts</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="footer-bottom text-white">
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p>Copyright © 2024 Smart Brain Co. Ltd,  All rights reserved. </p>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="social-link ">
                                <span>Social Links: </span>
                                <a className="social_links" target={'_blank'} href={!loading?web.facebook:'https://www.facebook.com/SmartBrainThailand/'}><i className="fab fa-facebook-f"></i></a>
                                {web.twitter ? <a className="social_links" target={'_blank'} href={!loading?web.twitter:''}><i className="fab fa-twitter"></i></a> : ''}
                                {/* <a target={'_blank'} href={!loading?web.linkedin:''}><i className="fab fa-linkedin-in"></i></a> */}
                                <a className="social_links" target={'_blank'} href={!loading?web.youtube:''}><i className="fab fa-youtube"></i></a>
                              
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default Footer;