import Nav from "../Component/Nav";
import React, {Fragment, useState} from "react";
import Footer from "../Component/Footer";
import API from "../Network/API";

function Registration() {
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [term, setTerm] = useState('');
    const [errors, setErrors] = useState({});
    const handleSubmit = event => {
        event.preventDefault();
        var data = {
            'name': name,
            'lastname': lastname,
            'email': email,
            'password': password,
            'user_type': 1,
        }
        API.post('register', data, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then(response => {
            if (response.data.success === true) {
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("token", response.data.data.token);
                window.location.href = "/dashboard";
            }else{
                console.log("failed")
            }
        })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.data) {
                    setErrors(error.response.data.data);
                } else {
                    // console.log("failed");
                    // console.log(error.response?.data?.data);
                }
            });
    }
    return (
        <Fragment>
            <Nav/>
            <div className="loginPage">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7 col-12">
                            <div className="loginTextArea">
                                <div className="loginHeading">
                                    <h1 className="mb-0 orangeRed">Sign up</h1>
                                    <h1>For play and learn</h1>
                                </div>
                                <p>“Let's work together to improve our children's performance with the help of experts.”</p>
                                <div className="loginBannerImg">
                                    <img src="assets/image/Signup-banner.svg" alt=""/>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-5 col-12">
                            <div className="loginFrom">

                                <form onSubmit={handleSubmit}>

                                    <h3 className="loginTitle">Sign Up</h3>
                                    <div className="row mb-3">
                                        <div className="col-6">
                                            <label htmlFor="inputEmail4" className="form-label">First Name</label>
                                            <input type="text" required onChange={(e) => setName(e.target.value)} className="form-control" id="inputEmail4"/>
                                            {errors.name && <div className="text-danger">{errors.name[0]}</div>}
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="inputPassword4" className="form-label">Last Name</label>
                                            <input type="text" required onChange={(e) => setLastname(e.target.value)} className="form-control" id="inputPassword4"/>
                                            {errors.lastname && <div className="text-danger">{errors.lastname[0]}</div>}
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="user_login" className="form-label">Email</label>
                                        <input className="form-control form-control-md" type="text" name=""
                                               id="user_login" required onChange={(e) => setEmail(e.target.value)}/>
                                        {errors.email && <div className="text-danger">{errors.email[0]}</div>}
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                        <input type="password" required onChange={(e) => setPassword(e.target.value)} className="form-control" id="exampleInputPassword1"/>
                                        {errors.password && <div className="text-danger">{errors.password[0]}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="gridCheck" required onChange={(e) => setTerm(e.target.value)}/>
                                            <label className="form-check-label" htmlFor="gridCheck">
                                                By signing up I agree with <a href="#"
                                                                              className="orangeRed btn-link">Terms &amp; conditions</a>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <button type="submit" className="btn btn-lg bg-black w-100 text-white">Submit</button>
                                        <p className="mt-2 mb-0">Already have an account? <a href="/login"
                                                                                             className="orangeRed btn-link">Login</a>
                                        </p>
                                    </div>

                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
);
}

export default Registration;