import Nav from "../Component/Nav";
import React, {Fragment, useEffect} from "react";
import Footer from "../Component/Footer";
import TrainingLeftNav from "./TrainingLeftNav";
import NoticeBoard from "./NoticeBoard";
import {Link} from "react-router-dom";
import { useSelector } from "react-redux";

export default function Training() {
    const profile_selector = useSelector((state) => state.profileReducer)
    useEffect(() => {
        // console.log(profile_selector?.data?.exam_level)
    }, []);
    return (
        <Fragment>
            <Nav/>
            <div className="studentDashboard">
                <div className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <TrainingLeftNav/>
                            </div>
                            <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="StudentDashboardContent">
                                    <h4 className=""> Dashboard </h4>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                        {profile_selector?.data?.exam_level?.name!=""?
                                          <>
                                          
                                          <div className="col-md-12">
                                                <Link to={`/exam-level/lesson/${profile_selector?.data?.exam_level?.uuid}`}>
                                                    <div
                                                        className="card gradientSoftBrand-bg mb-3 shadow-sm levelWidagetmaxHeight">
                                                        <div className="card-body">
                                                            <h4 className="">{profile_selector?.data?.exam_level?.name}</h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                          </>
                                          :''
                                        }
                                          
                                        </div>
                                        <div className="col-6">
                                            <Link to="/exam/category">
                                            <div className="card bg-soft-skyBlue mb-3">
                                                <div className="card-body text-center">
                                                    <p className="mb-1 fs-2">Exam</p>
                                                    <div className="">
                                                        <em className="fa-4x mr-2 fab fa-react"></em>
                                                    </div>

                                                </div>
                                                <div className="card-footer bg-dark text-center text-white">
                                                    <h4>
                                                        <a href="">
                                                            <em className="fa-3x mr-2 fas fa-play text-white"></em>
                                                        </a>
                                                    </h4>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                        <div className="col-6">
                                            <Link to="/training/practice">
                                            <div className="card bg-soft-indianRed mb-3">
                                                <div className="card-body text-center">
                                                    <p className="mb-1 fs-2">Practice</p>
                                                    <div className="">
                                                        <em className="fa-4x mr-2 fab fa-react"></em>
                                                    </div>

                                                </div>
                                                <div className="card-footer bg-dark text-center text-white">
                                                    <h4>
                                                        <Link to="/training/practice">
                                                            <em className="fa-3x mr-2 fas fa-play text-white"></em>
                                                        </Link>
                                                    </h4>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>

                                    </div>


                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <NoticeBoard/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
}