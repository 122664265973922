import Nav from "../Component/Nav";
import React, {Fragment, useState} from "react";
import Footer from "../Component/Footer";
import API from "../Network/API";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
toast.configure();

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const responseFacebook = (response) => {

        // console.log(response);
        // console.log(response.picture.data.url);
        var dataValue = {
            'fb_id': response.userID,
            'email': response.email,
            'name': response.name,
            'image': response.picture.data.url,
            'password': 12345,
            'user_type': 1,
            'login_by': 2
        }
        setLoading(true);
        if (response.accessToken) {
            API.post('facebook_login', dataValue, {
                onUploadProgress: progressEvent => {
                    console.log("Progress: " + progressEvent.loaded / progressEvent.total);
                }
            }).then(response => {
                setLoading(false);
                if (response.data.success === true) {
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem("token", response.data.data.token);
                    localStorage.setItem("is_training", response.data.data.is_training);
                    localStorage.setItem("currentUser",JSON.stringify(response.data.data.userData));
                    if(localStorage.getItem("url")!=null){
                        window.location.href=localStorage.getItem("url");
                    }else{
                        if (response.data.data.is_training==1){
                            window.location.href = "/student-dashboard";
                        }else{
                            window.location.href = "/dashboard";
                        }
                    }
                }
            }).catch((error) => {
                setLoading(false);
                toast.error('Login Failed.Please try again.');
            });
        }
    }

    const componentClicked = () => {
        //
    }

    const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);

        var data = {
            'email': email,
            'password': password,
        }
        
        API.post('login', data, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then(response => {
        setLoading(false);

            if (response.data.success === true) {
                toast.success('Login Successfully');
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("token", response.data.data.token);
                localStorage.setItem("is_training", response.data.data.is_training);
                localStorage.setItem("currentUser",JSON.stringify(response.data.data.userData));
                if(localStorage.getItem("url")!=null){
                    window.location.href=localStorage.getItem("url")=='null'?"/dashboard":localStorage.getItem("url");
                }else{
                    if (response.data.data.is_training==1){
                        window.location.href = "/student-dashboard";
                    }else{
                        window.location.href = "/dashboard";
                    }
                }
            }
        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.data) {
                setErrors(error.response.data.data);
            } else {
                // console.log("failed");
                // console.log(error.response?.data?.data);
            }
            // toast.error('Login Failed.Please try again.');
            });
    }

    const responseGoogle = (response) => {
        var dataValue = {
            'fb_id': response.profileObj.googleId,
            'email': response.profileObj.email,
            'name': response.profileObj.name,
            'image': response.profileObj.imageUrl,
            'password': 12345,
            'user_type': 1,
            'login_by': 3
        }

        if (response.accessToken) {
            API.post('facebook_login', dataValue, {
                onUploadProgress: progressEvent => {
                    console.log("Progress: " + progressEvent.loaded / progressEvent.total);
                }
            }).then(response => {
                if (response.data.success === true) {
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem("token", response.data.data.token);
                    localStorage.setItem("is_training", response.data.data.is_training);
                    localStorage.setItem("currentUser",JSON.stringify(response.data.data.userData));
                    if(localStorage.getItem("url")!=null){
                        window.location.href=localStorage.getItem("url");
                    }else{
                        if (response.data.data.is_training==1){
                            window.location.href = "/student-dashboard";
                        }else{
                            window.location.href = "/dashboard";
                        }
                    }
                }
            }).catch((error) => {
                toast.error('Login Failed.Please try again.');
            });
        }
    }

    console.log(localStorage.getItem('url'))
    return (
        <Fragment>
            <Nav/>
            <div className="loginPage">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7 col-12">
                            <div className="loginTextArea">
                                <div className="loginHeading">
                                    <h1 className="mb-0 orangeRed">Welcome back TO</h1>
                                    <h1>SMART BRAIN</h1>
                                </div>
                                <p>“Let's work together to improve our children's performance with the help of experts.”</p>
                                <div className="loginBannerImg">
                                    <img src="assets/image/login-banner.svg" alt=""/>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-5 col-12">
                            <div className="loginFrom">

                                <form onSubmit={handleSubmit}>

                                    <h3 className="loginTitle">Login</h3>

                                    <div className="form-group mb-3">
                                        <label htmlFor="user_login" className="form-label">Email</label>
                                        <input required onChange={(e) => setEmail(e.target.value)}
                                               className="form-control form-control-md" type="text" name=""
                                               id="user_login"/>
                                        {errors.email && <div className="text-danger">{errors.email[0]}</div>}
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                        <input required onChange={(e) => setPassword(e.target.value)} type="password"
                                               className="form-control" id="exampleInputPassword1"/>
                                        {errors.password && <div className="text-danger">{errors.password[0]}</div>}
                                    </div>
                                    <div className="mb-3 d-flex justify-content-between">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="gridCheck"/>
                                            <label className="form-check-label fleet-custom-label"
                                                   htmlFor="gridCheck">
                                                Remember
                                            </label>
                                        </div>
                                        <span className=""><Link to={'/forget-password'}
                                                              className="orangeRed btn-link">Forget Password</Link></span>
                                    </div>

                                    <div className="mb-3">
                                        <button type="submit" className="btn btn-lg bg-black w-100 text-white">Login{isLoading ? (
                                            <span
                                                className="spinner-border spinner-border-sm ml-5"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            <span></span>
                                        )}
                                        </button>
                                        <p className="mt-2 mb-0">Don’t have an account? <a href="/registration"
                                                                                           className="orangeRed btn-link">Sign
                                            up</a></p>
                                    </div>

                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
}

export default Login;