import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../Network/API";
import Footer from "./Footer";
import Nav from "./Nav";
import parse from 'html-react-parser';

function Page() {
    let { id } = useParams();
    const [page, setPage] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        API.get(`/page_details/${id}`).then(response => {
            setPage(response.data.data);
            setLoading(false);
        })

    }, [id]);
    return (
        <Fragment>
          <Nav/>
          <div className="section page-banner-section bg-black breadcrumb-img-bg">
        <div className="color-opacity">
          <div className="container">
            {/* Page Banner Content Start */}
            <div className="page-banner-content">
              <h2 className="title">{page?.title}</h2>
              <ul className="breadcrumb justify-content-center">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item active">{page?.title}</li>
              </ul>
            </div>
            {/* Page Banner Content End */}
          </div>
        </div>
      </div>

      <div className="section-padding">
        <div className="container">
          <div className="blog-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="blog-details-wrapper">
                  <div className="blog-details-image">
                    <a href="#">
                      <img src={page?.image} alt="Blog" />
                    </a>
                  </div>
                  <div className="blog-details-content">
                    <h3 className="title">{page?.title}</h3>
                    {!loading && page?.details!="undefiend"?parse(`${page?.details}`):''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        
          <Footer/>
        </Fragment>
    );
  }
  
  export default Page;
  