import banner from '../bd1.gif';
import API from "../Network/API";
import React, { Component, Fragment, props, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ContentLoader, { Facebook,Code  } from 'react-content-loader';
function Banner() {
    const [loading, setLoading] = useState(true);
    const [web, setWeb] = useState([]);
    useEffect(() => {
        API.get(`/slider`).then(response => {
            setWeb(response.data.data);
            setLoading(false);
        })
    }, []);
    return (
        <div className="banner">
            <div className="container">
                <div className="banner-padding">
                    {loading?
                     <ContentLoader 
                     speed={2}
                     width={600}
                     height={160}
                     viewBox="0 0 600 160"
                     backgroundColor="#f3f3f3"
                     foregroundColor="#ecebeb"
                     
                   >
                     <rect x="4" y="8" rx="3" ry="3" width="88" height="6" /> 
                     <rect x="7" y="24" rx="3" ry="3" width="52" height="6" /> 
                     <rect x="-13" y="57" rx="3" ry="3" width="330" height="5" /> 
                     <rect x="0" y="75" rx="3" ry="3" width="324" height="5" /> 
                     <rect x="0" y="91" rx="3" ry="3" width="326" height="5" />
                   </ContentLoader> 
                :
                    <div className="row">

                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="banner-text">
                                <h4 className="banner-sub-title-color">{!loading?web.title:''}</h4>
                                <h1 className="banner-title">{!loading?web.sub_title:''}</h1>
                                <h4 className="banner-sub-title">{!loading?web.sub_title_details:''}</h4>
                                <p className="banner-description">
                                {!loading?web.sort_details:''}
                                    {/* <a className="banner-link d-block">Learn More</a> */}
                                </p>

                                <a target={'_blank'} href={!loading?web.url:'#'} className="btn custom-btn banner-btn">Learn More</a>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="banner-img d-flex justify-content-center align-items-center">
                                <img src={!loading?web.image:'#'} alt="" className="header-banner-image"/>
                            </div>
                        </div>

                    </div>
}
                </div>
            </div>
        </div>
    );
}

export default Banner;