import Nav from "../Component/Nav";
import React, { Fragment, useState } from "react";
import Footer from "../Component/Footer";
import API from "../Network/API";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

function UpdatePassword() {
    const [new_password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = event => {
        event.preventDefault();
        var data = {
            'new_password': new_password,
            'confirm_password': confirm_password,
            'email': localStorage.getItem('email'),
            'otp_number': localStorage.getItem('otp_number'),
        }
        setLoading(true);
        API.post('send-otp', data).then(response => {
            setLoading(false);
            if (response.data.success === true) {
                toast.success('Password updated Successfully');
               
                window.location.href = "/login";
            }
        }).catch((error) => {
            setLoading(false);
            toast.error('Please try again.');
        });
    }

    return (
        <Fragment>
            <Nav />
            <div className="loginPage">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7 col-12">
                            <div className="loginTextArea">
                                <div className="loginHeading">
                                    <h1 className="mb-0 orangeRed">Welcome back TO</h1>
                                    <h1>SMART BRAIN</h1>
                                </div>
                                <p>“Let’s join in bettering our children’s
                                    performance with the help of experts.”</p>
                                <div className="loginBannerImg">
                                    <img src="assets/image/login-banner.svg" alt="" />
                                </div>
                            </div>

                        </div>
                        <div className="col-md-5 col-12">
                            <div className="loginFrom">

                                <form onSubmit={handleSubmit}>

                                    <h3 className="loginTitle">Update Password</h3>

                                    <div className="row mb-3">
                                        <div className="col-5"><span className="line-border"></span></div>
                                        <div className="col-2 text-center"><span className="line-text">or</span></div>
                                        <div className="col-5"><span className="line-border"></span></div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input required onChange={(e) => setPassword(e.target.value)}
                                            className="form-control form-control-md" type="password" name="password"
                                            id="password" />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="confirm_password" className="form-label">Confirm Password</label>
                                        <input required onChange={(e) => setConfirmPassword(e.target.value)}
                                            className="form-control form-control-md" type="password" name="confirm_password"
                                            id="confirm_password" />
                                    </div>

                                    <div className="mb-3">
                                        <button type="submit" className="btn btn-lg bg-black w-100 text-white">Update Password{isLoading ? (
                                            <span
                                                className="spinner-border spinner-border-sm ml-5"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            <span></span>
                                        )}
                                        </button>
                                        <p className="mt-2 mb-0">Don’t have an account? <a href="/registration"
                                            className="orangeRed btn-link">Sign
                                            up</a></p>
                                    </div>

                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default UpdatePassword;