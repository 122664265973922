import React, {Fragment, useState} from "react";
import API from "../../Network/API";
import CardLogo from "../../assets/images/card.svg";

export default function CardDetails({course}){

    const [payment_method, setPaymentMethod] = useState('');
    const [term, setTerm] = useState('');

    const handleSubmit = event => {
        event.preventDefault();
        var ref_code=localStorage.getItem("referral_code")??null;
        var dataValue = {
            'payment_method': payment_method,
            'term': term,
            'course_id': course.id,
            'course_price': course.course_price,
            'referral_code': ref_code,
        }
        API.post('checkout', dataValue, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then(response => {
            if (response.data.success === true) {
                if (response.data.data.response_code==="0000"){
                    localStorage.setItem("referral_code",null);
                    window.location.href=response.data.data.payment_url;
                }
            }
        })
            .catch((error) => {
                console.log(error);
            });
    }
    return(
        <Fragment>
             <div className="row">
                 <form onSubmit={handleSubmit}>
                 <div className="col-md-12 mb-3">
                <div className="courseContentAccordion">
                  <div className="accordion bg-light-white " id="accordionExample">
                    <div className="accordion-item bg-light-white borderBrandColor">
                      <div className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" >
                          <div className="radio-item">
                            <input onChange={(e) => setPaymentMethod(e.target.value)}  required={true} type="radio" id="ritemb" name="payment_method" value="1" />
                            <label htmlFor="ritemb">Cards / Internet or Mobile Banking / Web Pay / ATM</label>
                          </div>
                          <span className="lecturesHour payment_cards"><span className="me-1">
                            <img class="visa-card" src="https://d27uu9vmlo4gwh.cloudfront.net/images/v4/images/icon/master.png" alt=""/> 
                            <img class="visa-card" src="https://d27uu9vmlo4gwh.cloudfront.net/images/v4/images/icon/visa.png" alt=""/> 
                            <img class="visa-card" src="https://d27uu9vmlo4gwh.cloudfront.net/images/v4/images/icon/KBANK.png" alt=""/> 
                            <img class="visa-card" src="https://d27uu9vmlo4gwh.cloudfront.net/images/v4/images/icon/SCB.png" alt=""/> 
                            <img class="visa-card" src="https://d27uu9vmlo4gwh.cloudfront.net/images/v4/images/icon/BBL.png" alt=""/> 
                            <img class="visa-card" src="https://d27uu9vmlo4gwh.cloudfront.net/images/v4/images/icon/KTB.png" alt=""/> 
                            <img class="visa-card" src="https://d27uu9vmlo4gwh.cloudfront.net/images/v4/images/icon/BAY.png" alt=""/> 
                            <img className="visa-card" src="https://upload.wikimedia.org/wikipedia/commons/3/39/2C2P_Logo.png" alt /></span>
                          </span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="form-check">
                  <input onChange={(e) => setTerm(e.target.value)}  required={true} className="form-check-input" type="checkbox" id="gridCheck2" name="terms" value={1} />
                  <label className="form-check-label" htmlFor="gridCheck2">
                    By completing my purchase I agree with these <span className="orangeRed">Terms of Service.</span>
                  </label>
                </div>
              </div>
              <div className="col-12 mb-3">
                <p>N.B. Smart Brain is required by law to collect applicable transaction taxes for purchases made
                  in certain tax jurisdictions.</p>
              </div>
              <div className="col-12 text-center mb-3">
                <button type="submit" className="btn btn-lg orangeRed-bg text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">Complete Payment</button>
              </div>
                </form>
            </div>
        </Fragment>
    );
}