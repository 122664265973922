import { createStore, applyMiddleware,combineReducers } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './Reducer/rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import authReducers from './Reducer/authSlice';

const store = configureStore({
    reducer: rootReducer,
    auth: authReducers,
    middleware:[thunk]
})

export default store