import Nav from "../Component/Nav";
import React, { Fragment, useEffect, useState } from "react";
import Footer from "../Component/Footer";
import { useParams } from "react-router-dom";
import API from "../Network/API";
import TrainingLeftNav from "../Training/TrainingLeftNav";
import ExamHeader from "./ExamHeader";
import ExamBody from "./ExamBody";

const questions = [
    {
        question: "In what contintent is Indonesia?",
        answers: ["South America", "Europe", "Asia"],
        correctAnswerIndex: 2
    },
    {
        question: "What is 64 * 3",
        answers: [186, 192, 197, 400],
        correctAnswerIndex: 1
    },
    {
        question: "How many faces does a decahedron have?",
        answers: [8, 10, 12],
        correctAnswerIndex: 1
    },
    {
        question: "What's the capital city of England?",
        answers: ["London", "Paris", "Berlin"],
        correctAnswerIndex: 0
    },
    {
        question: "Is JavaScript the same as Java?",
        answers: ["Yes, it is", "No, it isn't"],
        correctAnswerIndex: 1
    },
    {
        question: "What month comes after November?",
        answers: ["January", "March", "December"],
        correctAnswerIndex: 2
    }
];
export default function ExamMain() {
    const [loading, setLoading] = useState(false);
    const [training_quiz, setQuiz] = useState([]);
    const [lesson, setLesson] = useState([]);
    let { id } = useParams();
    useEffect(() => {
        setLoading(true);
        API.get(`/json_exam_level_lesson_quiz/${id}`).then(response => {
            setQuiz(response.data.data.exam_question);
            setLesson(response.data.data.lesson);
            setLoading(false);
        })

    }, []);

    return (
        <Fragment>
            <Nav />
            <div className="studentDashboard">
                <div className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <TrainingLeftNav />
                            </div>

                            <div className="col-12 col-sm-0 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                                {loading ?
                                    <span>Loading....</span>
                                    :
                                    <>
                                        {/* <ExamHeader startTime={new Date(lesson.start_time)} endTime={new Date(lesson.end_time)} is_time={true} duration={lesson.duration} duration_millisecond={lesson.duration_millisecond} /> */}
                                        <ExamBody lesson={lesson} endTime={new Date(lesson.end_time).getTime()} duration={lesson.duration} duration_millisecond={lesson.duration_millisecond} questions={training_quiz} />
                                    </>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}