import { Fragment } from 'react';
import ContentLoader  from 'react-content-loader';

export default function CoursePlaceholder(){
    return ( 
   <Fragment>
       
    <ContentLoader 
    speed={3}
    width={300}
    height={280}
    viewBox="0 0 300 280"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb" className="col-md-3"
  >
    <rect x="3" y="3" rx="10" ry="10" width="300" height="200" /> 
    <rect x="6" y="190" rx="0" ry="0" width="292" height="20" /> 
    <rect x="4" y="215" rx="0" ry="0" width="239" height="20" /> 
    <rect x="4" y="242" rx="0" ry="0" width="274" height="20" /> 
 
    
  </ContentLoader>

   </Fragment>
  );
}