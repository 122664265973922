import React, { Fragment, props, useState, useEffect } from "react";
import API from "../../Network/API";
import Footer from "../../Component/Footer";
import Nav from "../../Component/Nav";
import ContentLoader, { Facebook } from 'react-content-loader';
import LectureSection from "./LectureSection";
import VideoContent from "./VideoContent";
import VideoPlayer from "./VideoPlayer";
import { BrowserRouter as Router, Redirect, Switch, Route, Link, useParams, useNavigate } from "react-router-dom";
export default function Lecture() {
  const navigate = useNavigate()
  const [lectureData, setLecture] = useState([]);
  const [sections, setSection] = useState([]);
  const [courses, setCourses] = useState([]);
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [mark_loading, setMarkLoading] = useState(false);
  const [content, setContent] = useState([]);
  let count = 0;
  useEffect(() => {
    setLoading(true);
    if (localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined') {
      API.get(`/private_lecture/${id}`).then(response => {
        setLecture(response.data.data.lecture);
        setSection(response.data.data.section);
        setCourses(response.data.data.course);
        setLoading(false);
      })

      API.get(`/check_course_mark?data=${id}`).then(response => {
        setContent(response.data.data)
      })

    } else {
      API.get(`/lecture/${id}`).then(response => {
        setLecture(response.data.data.lecture);
        setSection(response.data.data.section);
        setCourses(response.data.data.course);
        setLoading(false);
      })
    }


  }, [id]);

  function handleCheck(val) {
    return content.some(item => val === item);
  }
  
  function markCourse() {
    setMarkLoading(true);
    API.get(`/mark_course_lacture?data=${id}&is_complete=1&priority_index=0&watch_duration=0`)
      .then(response => {
        var datt = response.data.data;
        if (datt.course_complete == 1) {
          navigate(`/dashboard`)
          return;
        }
        if (datt.uid != null) {
          if (datt.type == 0) {
            navigate(`/lecture/${datt.uid}/${datt.slug}`)
          } else if (datt.type == 1) {
            navigate(`/course_quiz/${datt.uid}/${datt.slug}`)
          }

        }
        setMarkLoading(false);
      })
  }

  return (
    <Fragment>
      <Nav />
      <div className="courseDetails">
        <div className>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-8 col-xxl-8">
                <VideoPlayer data={lectureData} load={loading} />
                <div className="text-center">
                  <button onClick={() => markCourse()} className="btn btn-outline-warning mb-2">Complete & Continue <i className="fas fa-arrow-right"></i></button>
                </div>
                <VideoContent data={lectureData} course={courses} load={loading} />
              </div>
              <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4 col-xxl-4">
                {/* <LectureSection course={courses} data={sections} load={loading} /> */}

                <div className="coursePriceSection">
                  <div className="card bg-light-white shadow-sm">
                    <h4 className="learning-video-title">Course content</h4>
                    <div className="learnContentAccordion">
                      <div className="accordion accordion-flush  bg-light-white" id="accordionFlushExample">
                        {sections?.map((section, index) => (
                          <div>
                            {section.total_lecture > 0 ?
                              <div className="accordion-item bg-light-white">
                                <h2 className="accordion-header" id="flush-headingOne">
                                  <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseOne${section.id}`} aria-expanded="false" >
                                    <div className="videoContent">
                                      <span className="title">{section.title}</span>
                                      <span className="duration">{section.total_lecture} | {section.total_hour}</span>
                                    </div>
                                  </div>
                                </h2>
                                <div id={`flush-collapseOne${section.id}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample" style={{}}>
                                  <div className="accordion-body bg-white p-2">
                                    <div className="d-flex">

                                      <div className="flex-grow-1">

                                        <div className="rating mt-0">
                                          <ul className="courseVideoListing">
                                          <>
                                                            {section?.sub_sections?.map((sect, k) => (
                                                                    <>
                                                                    {sect?.lectures.length>0?
                                                                <div key={k} className="accordion-item bg-light-white">
                                                                    <div className="accordion-header" id={`headingOne${sect.id}`}>
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOneSec${sect.id}`} aria-expanded="false" aria-controls="collapseOneSec">
                                                                            {sect.title}
                                                                        </button>
                                                                    </div>
                                                                    <div id={`collapseOneSec${sect.id}`} className="accordion-collapse collapse" aria-labelledby={`headingOne${sect.id}`} data-bs-parent="#accordionExample1" >
                                                                        <div className="accordion-body bg-white">
                                                                            <ul className="courseVideoListing">
                                                                                {sect?.lectures?.map((lecture, k) => (
                                                                                    <>
                                                                                                         {lecture.is_paid == 0 ?
                                                  <li><Link style={!handleCheck(lecture.id) ? {pointerEvents: "none"} : null} to={`${process.env.PUBLIC_URL}/lecture/${lecture.uid}/${lecture.slug}`}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3 ${id == lecture.uid ? `text-success` : ``}`}></i>{lecture.title} </Link></li>
                                                  :

                                                  <li><Link style={!handleCheck(lecture.id) ? {pointerEvents: "none"} : null}  to={`${courses.is_purchased == 1 && lecture.type == 0 ? `${process.env.PUBLIC_URL}/lecture/${lecture.uid}/${lecture.slug}` : courses.is_purchased == 1 && lecture.type == 1 ? `${process.env.PUBLIC_URL}/course_quiz/${lecture.uid}/${lecture.slug}` : ""}`}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3 ${id == lecture.uid ? `text-success` : ``}`}></i>{lecture.title}</Link></li>
                                                }
                                                                                    </>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                                :''}
                                                                </>

                                                            ))}
                                                        </>
                                            {section.lecture?.map((lecture, index) => (
                                              <div>
                                                {lecture.is_paid == 0 ?
                                                  <li><Link style={!handleCheck(lecture.id) ? {pointerEvents: "none"} : null} to={`${process.env.PUBLIC_URL}/lecture/${lecture.uid}/${lecture.slug}`}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3 ${id == lecture.uid ? `text-success` : ``}`}></i>{lecture.title}</Link></li>
                                                  :

                                                  <li><Link style={!handleCheck(lecture.id) ? {pointerEvents: "none"} : null}  to={`${courses.is_purchased == 1 && lecture.type == 0 ? `${process.env.PUBLIC_URL}/lecture/${lecture.uid}/${lecture.slug}` : courses.is_purchased == 1 && lecture.type == 1 ? `${process.env.PUBLIC_URL}/course_quiz/${lecture.uid}/${lecture.slug}` : ""}`}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3 ${id == lecture.uid ? `text-success` : ``}`}></i>{lecture.title}</Link></li>
                                                }
                                              </div>
                                            ))}
                                          </ul>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              : ""
                            }
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
}