import API from "../Network/API";
import React, { Component, Fragment, props, useState, useEffect } from "react";

function ResourceHome() {
    const [loading, setLoading] = useState(false);
    const [web, setWeb] = useState([]);
    useEffect(() => {
        API.get(`/resource`).then(response => {
            setWeb(response.data.data);
            setLoading(false);
        })
    }, []);
    return (
        <div className="resource bg-off-white">
            <div className="container">
                <div className="section-padding pb-80 pt-80">
                    <div className="row  justify-content-center">
                        <div className="col-md-6 col-sm-8">
                            <div className="section-heading text-center pb-20 pt-4">
                                <h1 className="title">Who we are?</h1>
                                <p className="discretion">{web?.section_details}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-10 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="card resource-card shadow gradient-bg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <div className="resource-signal-icon">
                                                <img src="assets/image/resource%20(1).svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-md-3 ms-sm-1 ms-3">
                                            <p className="resource-heading">Countries</p>
                                            <h3 className="resource-number">{web?.total_country}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-10 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="card resource-card shadow gradient-bg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <div className="resource-signal-icon">
                                                <img src="assets/image/resource%20(2).svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-md-3 ms-sm-1 ms-3">
                                            <p className="resource-heading">Brances</p>
                                            <h3 className="resource-number">{web?.total_branch}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-10 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3 ">
                            <div className="card resource-card shadow gradient-bg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <div className="resource-signal-icon">
                                                <img src="assets/image/resource%20(3).svg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-md-3 ms-sm-1 ms-3">
                                            <p className="resource-heading">Students</p>
                                            <h3 className="resource-number">{web?.total_student}</h3>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ResourceHome;