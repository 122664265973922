import { Fragment } from "react";
import {Link,useLocation} from "react-router-dom";

export default function SideBar(){
    const location = useLocation();
        return (
            <Fragment>
                  <nav className="sidebar customSidebar bg-light-white mb-4 mobile-offcanvas rounded" id="navbar_main">
              <div className="offcanvas-header">
                <button className="btn-close float-end" />
              </div>
              <ul className="nav flex-column">
                <li className="nav-item sidebarMenuItem">
                  <Link className={`nav-link sidebarMenuLink ${location.pathname=='/dashboard'?'active':''}`} to={`${process.env.PUBLIC_URL}/dashboard`}>
                    <i className="fas fa-th-large sidebarMenuIcon" /> Dashboard
                  </Link>
                </li>
                <li className="nav-item sidebarMenuItem">
                  <Link className="nav-link sidebarMenuLink" to={`${process.env.PUBLIC_URL}/student-dashboard`}>
                  <i className="fa fa-pen sidebarMenuIcon" /> Training </Link>
                </li>
                <li className="nav-item sidebarMenuItem">
                  <Link to={`${process.env.PUBLIC_URL}/my-courses`} className={`nav-link sidebarMenuLink ${location.pathname=='/my-courses'?'active':''}`} >
                  <i className="fas fa-play sidebarMenuIcon" /> My Courses </Link>
                </li>
                <li className="nav-item sidebarMenuItem">
                  <Link to={`${process.env.PUBLIC_URL}/my-wishlist`} className={`nav-link sidebarMenuLink ${location.pathname=='/my-wishlist'?'active':''}`} ><i className="far fa-heart  sidebarMenuIcon" /> My Wishlist </Link>
                </li>
                <li className="nav-item sidebarMenuItem">
                  <Link className="nav-link sidebarMenuLink" to={`${process.env.PUBLIC_URL}/affiliate`}><i className="fas fa-clone sidebarMenuIcon" /> Affiliate </Link>
                </li>
              
              </ul>
            </nav>
            </Fragment>
        );
}