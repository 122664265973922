import { Fragment } from "react";
import Footer from "../Component/Footer";
import Nav from "../Component/Nav";
import TrainingLeftNav from "../Training/TrainingLeftNav";

export default function ExamTimeOver() {
    return (
        <Fragment>
            <Nav />
            <div className="studentDashboard">
                <div className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <TrainingLeftNav />
                            </div>
                            <div className="col-12 col-sm-0 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                            <h6>Your Exam time is over</h6>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}