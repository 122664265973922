import { Fragment } from "react";

export default function CartSummery({ data }) {
    return (
        <Fragment>
            <div className="coursePriceSection">
                <div className="card bg-light-white shadow">
                    <div className="card-body">
                        <h3 className>Summary</h3>
                        <img src={ data.cover } className="rounded" alt={ data.name } />
                        <h5 className="course-title mt-3">{ data.name }</h5>
                        <p className="author-name">Course by { data.teacher }</p>
                        <p className="course-description">{data.short_details}</p>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span>Total</span>
                            <span>{data.currency_code}{data.course_price}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}