

import { Fragment, useEffect, useState } from "react";
import Footer from "../Component/Footer";
import Nav from "../Component/Nav";

import API from "../Network/API";
import ContentLoader, { Facebook, Code } from 'react-content-loader';
import { Link } from "react-router-dom";
import SideBar from "../User/partials/SideBar";
function Affiliate() {
    const [applyed, setApplyed] = useState([]);
    const [dashboard, setDashboard] = useState([]);
    const [order, setOrder] = useState([]);
    const [loading, setLoading] = useState(false);
    const self_data = JSON.parse(localStorage.getItem("user_data"));
    const [earning_histories, setEarningHistories] = useState([]);
    const [dloading, setDLoading] = useState(false);

    useEffect(() => {
      
        if(self_data.is_apply_affiliate==1){
            setApplyed(true);
        }
        setDLoading(true);
        API.get(`/earning_history`).then(response => {
            setEarningHistories(response.data.data.order_history);
            setDLoading(false);
        }).catch((error) => {
           
        });
        
    }, []);


    const submit_request=()=>{
        setLoading(true)
        API.get(`/affilate_apply`).then(response => {
            if (response.data.success === true) {
                setLoading(false)
                setApplyed(true);
            }else{
               
            }
        }).catch((error) => {
           
        });
    }


    return (
        <Fragment>
            <Nav />
            <div className="studentDashboard">
                <div className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="DashboardSideBar ">
                                    <SideBar />
                                </div>
                            </div>
                            <div className="col-12 col-sm-8 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                                {self_data.is_apply_affiliate == 0 || self_data.is_apply_affiliate == 1 ?
                                    <>
                                        <div className="StudentDashboardContent">
                                            <span>Welcome to</span>
                                            <h4 className>Affiliate dashboard</h4>

                                            <div class="ac-welcome-page-banner-container">
                                            <div class="ac-welcome-join-container">
                                                <div class="a-row a-spacing-none banner-content ac-welcome-join ac-welcome-join-bg"><div class="a-column a-span12 a-spacing-extra-large ac-bold-text-v2 ac-light-font banner-primary-text">Recommend Products. Earn Commissions.
                                                </div>
                                                    <div class="a-column a-span12 a-spacing-none a-spacing-top-micro">
                                                        <span class="a-declarative" >
                                                            <span class="a-button a-button-primary" id="a-autoid-0">
                                                                <span class="a-button-inner">
{applyed==true? 'Pending for approval':
                                                                    <a href="#" class="a-button-text" id="a-autoid-0-announce" onClick={()=>submit_request()}>Become an affiliate {loading ? (
                                                    <span
                                                        className="spinner-border spinner-border-sm ml-5"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                ) : (
                                                    <span></span>
                                                )}
                                                                    </a>
                                                                }
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="StudentDashboardContent">
                                            <span>Welcome to</span>
                                            <h4 className>Affiliate dashboard</h4>
                                            <div className="row mb-3">
                                                <div className="col-6">
                                                    <div className="card bg-light-white">
                                                        <div className="card-body">
                                                            <p className="mb-1">Total Earning</p>
                                                            <h2 className="mb-2">$ {self_data.total_commission??0}</h2>
                                                            <div className="d-flex justify-content-end">
                                                                <button className="btn btn-link btn-link-color"><Link to="/">View List</Link></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="card bg-light-white">
                                                        <div className="card-body">
                                                            <p className="mb-1">Total Withdraw</p>
                                                            <h2 className="mb-2">$ {self_data.total_withdraw??0}</h2>
                                                            <div className="d-flex justify-content-end">
                                                                <button className="btn btn-link btn-link-color">View List</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="StudentDashboardContent">
                                    <h4 className=""> Earning History </h4>
                                    <div className="row mb-3">
                                        <div className="rounded">
                                            <div className="table-responsive">
                                                {dloading && [1,2,3,4]?.map(practice => (
                                                    <Code/>
                                                ))}
                                                {!dloading ?
                                                    <table className="table">
                                                        <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Date</th>
                                                            <th>Order ID</th>
                                                            <th>From</th>
                                                            <th>Total Commission</th>
                                                            <th>Source</th>
                                                            <th>Status</th>
                                                            
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {!loading && earning_histories?.map(order => (
                                                            <tr>
                                                                <th scope="row">{order.id}</th>
                                                                <td>{order.date}</td>
                                                                <td>{order.order_no}</td>
                                                                <td>{order.from_user}</td>
                                                                <td>{ order.total_commission }</td>
                                                                <td>
                                                                { order.order_details?.map(course => (
                                                                    <>
                                                                    <li>{course.course_name}</li>
                                                                    </>
                                                                ))}
                                                                </td>
                                                                <td>
                                                                    {order.status}
                                                                </td>
                                                                
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                    : " "}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                        </div>
                                    </>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    );
}
export default Affiliate;