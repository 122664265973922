import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ initialSeconds, onCountdownEnd }) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);


      return () => clearTimeout(timerId);
    } else {
        onCountdownEnd();
    }
  }, [seconds, onCountdownEnd]);

  return (
    <>
     
      <div class="countdown">
    
     <div>
      <span class="number hours">0</span>
      <span>Hours</span>
    </div>
     <div>
      <span class="number minutes">0</span>
      <span>Minutes</span>
    </div>
     <div>
      <span class="number seconds">{seconds}</span>
      <span>Seconds</span>
    </div>
  </div>
    </>
    
  );
};

export default CountdownTimer;