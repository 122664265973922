import offline from '../offline.css';
import React, {Fragment} from "react";
import useNetwork from "../Network/useNetwork";
import NetworkInfoTable from "../Network/NetworkInfoTable";
const OfflinePage = () => {
    const networkState = useNetwork();
    const {
        online,
        since,
        downLink,
        downLinkMax,
        effectiveType,
        rtt,
        saveData,
        type,
    } = networkState;
    return (
        <Fragment>
        <div className="wrapper">
            <h1 className="wrapper-h1">OFFLINE</h1>
            <h4 className="wrapper-h2">Please check your internet connection</h4>
            <a href="." className="wrapper-button">RETRY</a>
        </div>
            {/*<h1>Network Stats:</h1>*/}
            {/*<NetworkInfoTable data={networkState} />*/}
        </Fragment>
    );
}

export default OfflinePage;
