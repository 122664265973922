import React, {useState, useEffect} from "react";
import Course from "../Props/Course";
import API from "../Network/API";
import ContentLoader, { Facebook,Code  } from 'react-content-loader';
function NewCourseHome() {
    const [courses,setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        API.get(`/hot_courses`).then(response => {
            setCourses(response.data.data);
            setLoading(false);
        })
       
    }, []);
    return (
        <div className="new-course">
            <div className="container">
                <div className="section-padding pb-60">
                    <div className="row">
                        <div className="section-heading pb-20">
                            <h4 className="title-hot-course">Latest courses</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12-xs child">
                            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators"
                                            data-bs-slide-to="0"
                                            className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators"
                                            data-bs-slide-to="1"
                                            aria-label="Slide 2"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active" data-bs-interval="10000">
                                        <div className="row justify-content-center">
                                        {loading && <Code  />}
                                            {!loading && courses.map((course,i) =>(
                                                i % 2 == 0 ? <Course key={i} data={course} /> : ""
                                                ))}
                                                
                                        </div>
                                    </div>

                                    <div className="carousel-item" data-bs-interval="10000">
                                        <div className="row justify-content-center">
                                        {loading && <Code  />}
                                            {!loading && courses.map((course,i) =>(
                                                i % 2 == 0 ? "" : <Course key={i} data={course} />
                                                ))}
                                        </div>
                                    </div>
                                   
                              
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewCourseHome