import { Fragment } from "react";

export default function VideoContent({ data,load,course }){
    return (
        <Fragment>
            <div className="learningSection mb-5">
            <div className="card bg-light-white">
              <div className="learningPadding">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Overview</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="rReviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false">Reviews</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Q&amp;A</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Notes</button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className="pt-4 pb-4">
                      <h4>Note</h4>
                      <p>{data.note_attachment}</p>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <div>
                        By the numbers
                      </div>
                      <div>
                        <div>Skill level: All Levels</div>
                        <div>Students: {course.total_students}</div>
                        <div> Languages: {course.language}</div>
                        <div>  Captions: Yes</div>
                      </div>
                      <div>
                        <div>Lectures: {course.count_lacture}</div>
                        <div>Video: {course.total_duration} total hours</div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                    <div className="courseRating pt-4 pb-4">
                      <h6 className="courseDetailsHeading">Reviews ({course.total_review})</h6>
                      <div className="courseRatingSorting">
                        <div className="row mb-4">
                          <div className="col-8">
                            <div className="input-group">
                              <input type="text" className="form-control" id placeholder="Search review" />
                              <div className="input-group-text bg-black text-white"><i className="fas fa-search" />
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <select className="form-select" id="inlineFormSelectPref">
                              <option selected>All ratings</option>
                              <option value={1}>One</option>
                              <option value={2}>Two</option>
                              <option value={3}>Three</option>
                            </select>
                          </div>
                        </div>
                        {course.course_review?.map((review,i) => (
                            <div class="signalRating mb-3">
                                <div class="card shadow-sm">
                                    <div class="card-body">
                                        <div class="d-flex">
                                            <div class="flex-shrink-0">
                                                <img src={review.user.avatar} alt="..." height="50px" width="50px" class="rounded-circle"/>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <div class="signalRatingHeader d-flex">
                                                    <h5 class="m-0 reviewsRatingColor">{review.user.name}</h5>
                                                    <span class="ms-auto">{ review.created_at}</span>
                                                </div>
                                                <div class="rating mb-2">
                                                    <span class="rating-icon">
                                                    <i className={`fas fa-star ${review.rating>0?'':'text-dark'} `}></i>
                                    <i className={`fas fa-star ${review.rating>1?'':'text-dark'} `}></i>
                                    <i className={`fas fa-star ${review.rating>2?'':'text-dark'} `}></i>
                                    <i className={`fas fa-star ${review.rating>3?'':'text-dark'} `}></i>
                                    <i className={`fas fa-star ${review.rating>=5?'':'text-dark'} `}></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <p>{ review.comment}</p>
                                    </div>
                                </div>
                            </div>
                           ))}
                        <a href="#" className="btn btn-link reviewsRatingColor d-flex justify-content-end">See more
                          reviews</a>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
                  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
    );
}