import { combineReducers } from 'redux';
import homeReducer from './homeSlice';
import authReducers from './authSlice';
import MyProfileSlice from './MyProfileSlice';


export default combineReducers({
    homeReducer:homeReducer,
    authReducer:authReducers,
    profileReducer:MyProfileSlice,
    
});