import React, {Fragment, useEffect, useState} from "react";
import Nav from "../../Component/Nav";
import Footer from "../../Component/Footer";
import TrainingLeftNav from "../TrainingLeftNav";
import API from "../../Network/API";
import CoursePlaceholder from "../../Network/CoursePlaceholder";
import Course from "../../Props/Course";
import {Link} from "react-router-dom";
import NoticeBoard from "../NoticeBoard";
import ContentLoader from "react-content-loader";

export default function TrainingCategory() {
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    useEffect(() => {

        setLoading(true);
        API.get(`/training_practice`).then(response => {
            setCategories(response.data.data);
            setLoading(false);
        })

    }, []);
    return (
        <Fragment>
            <Nav/>
            <div className="studentDashboard">
                <div className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <TrainingLeftNav/>
                            </div>
                            <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="StudentDashboardContent">
                                    <h4 className=""> Practice </h4>

                                    <div className="row mb-3">
                                        {loading && [1,2,3]?.map(category => (
                                            <div className="col-md-6">

                                                <ContentLoader
                                                    speed={2}
                                                    width='100%'
                                                    height={200}
                                                    viewBox="0 0 100% 200"
                                                    backgroundColor="#f3f3f3"
                                                    foregroundColor="#ecebeb"
                                                >
                                                    <rect x="24" y="47" rx="0" ry="0" width="100%" height="100%"/>
                                                </ContentLoader>

                                            </div>
                                        ))}
                                          {!loading && categories?.length==0? <> <div className="col-12  text-center"> <img src="/assets/image/notfound.png"/> </div> </> :'' }

                                        {!loading && categories?.map(category => (
                                            
                                            <div className="col-6" key={category.id}>
                                                <Link to={`/training/practice/level/${category.uuid}`}>
                                                <div className="card bg-soft-skyBlue mb-3">
                                                    <div className="card-body">
                                                        <p className="mb-1">{category.name}</p>
                                                        <div className="d-flex justify-content-start align-items-end">
                                                            <h2 className="mb-2 me-2">{category.total_lesson}</h2>
                                                            <h6 className="">Lesson</h6>
                                                        </div>

                                                    </div>
                                                </div>
                                                </Link>
                                            </div>
                                           
                                        ))}



                                    </div>


                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                             <NoticeBoard/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>

        </Fragment>
    );
}