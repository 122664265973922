import React from "react";
import {Link,useLocation} from "react-router-dom";

export default function TrainingLeftNav() {
    const location = useLocation();
    return (
        <div className="DashboardSideBar ">
            <nav className="sidebar customSidebar bg-light-white mb-4 mobile-offcanvas rounded"
                 id="navbar_main">
                <div className="offcanvas-header">
                    <button className="btn-close float-end"></button>
                </div>
                <ul className="nav flex-column">
                    <li className="nav-item sidebarMenuItem">
                        <Link className={`nav-link sidebarMenuLink ${location.pathname=='/student-dashboard'?'active':''}`} to={`${process.env.PUBLIC_URL}/student-dashboard`}>
                            <i className="fas fa-th-large sidebarMenuIcon"></i> Home
                        </Link>
                    </li>
                    <li className="nav-item sidebarMenuItem">
                        <Link  className={`nav-link sidebarMenuLink ${location.pathname=='/training/practice'?'active':''}`} to="/training/practice"><i
                            className="fas fa-marker sidebarMenuIcon"></i> Practice </Link>
                    </li>
                    <li className="nav-item sidebarMenuItem">
                        <Link  className={`nav-link sidebarMenuLink ${location.pathname=='/training/practice/history'?'active':''}`} to="/training/practice/history"><i
                            className="fas fa-calendar sidebarMenuIcon"></i> Practice History </Link>
                    </li>
                    <li className="nav-item sidebarMenuItem">
                        <Link className={`nav-link sidebarMenuLink ${location.pathname=='/exam/category'?'active':''}`} to="/exam/category"><i
                            className="fas fa-school sidebarMenuIcon"></i> Exam </Link>
                    </li>
                    <li className="nav-item sidebarMenuItem">
                        <Link  className={`nav-link sidebarMenuLink ${location.pathname=='/exam/history'?'active':''}`} to="/exam/history"><i
                            className="fas fa-table sidebarMenuIcon"></i> Exam History </Link>
                    </li>
                    <li className="nav-item sidebarMenuItem">
                        <Link className={`nav-link sidebarMenuLink`} to="/dashboard"><i
                            className="fas fa-video sidebarMenuIcon"></i> Video Courses </Link>
                    </li>

                </ul>
            </nav>
        </div>
    );
}