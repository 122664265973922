import React, {Fragment, useEffect, useState} from "react";

import Nav from "../../Component/Nav";
import Footer from "../../Component/Footer";
import TrainingLeftNav from "../../Training/TrainingLeftNav";
import CoursePlaceholder from "../../Network/CoursePlaceholder";
import {Link, useParams,useNavigate} from "react-router-dom";
import API from "../../Network/API";
import NoticeBoard from "../../Training/NoticeBoard";
import ContentLoader from "react-content-loader";

export default function ExamLevel() {
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [levels, setLevels] = useState([]);
    let { id } = useParams();
    useEffect(() => {
        setLoading(true);
        API.get(`/json_exam_level/${id}`).then(response => {
            setCategories(response.data.data.category);
            setLevels(response.data.data.levels);
            setLoading(false);
        })

    }, []);
    let history = useNavigate();
    return (
        <Fragment>
            <Nav/>
            <div className="studentDashboard">
                <div className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <TrainingLeftNav/>
                            </div>
                            <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="StudentDashboardContent">
                                    <h4 className=""> Level </h4>
                                    <p>Exam > <a href="#" onClick={() => history(-1)}>Exam Category</a> > {categories?.name}</p>
                                    <div className="row mb-3">
                                        {loading && [1,2,3]?.map(category => (
                                            <div className="col-md-12">

                                                <ContentLoader
                                                    speed={2}
                                                    width='100%'
                                                    height={150}
                                                    viewBox="0 0 100% 150"
                                                    backgroundColor="#f3f3f3"
                                                    foregroundColor="#ecebeb"
                                                >
                                                    <rect x="24" y="47" rx="0" ry="0" width="100%" height="100%"/>
                                                </ContentLoader>

                                            </div>
                                        ))}
                                           {!loading && levels?.length==0? <> <div className="col-12  text-center"> <img src="/assets/image/notfound.png"/> </div> </> :'' }

                                        {!loading && levels?.map(category => (
                                            <div className="col-md-12">
                                                <Link to={`/exam-level/lesson/${category.uuid}`}>
                                                    <div
                                                        className="card gradientSoftBrand-bg mb-3 shadow-sm levelWidagetmaxHeight">
                                                        <div className="card-body">
                                                            <h4 className="">{category.name}</h4>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}



                                    </div>


                                </div>
                            </div>
                            <div className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <NoticeBoard/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
}