import React, {useContext, createContext, useState} from 'react';
import App from './App';
import {
    BrowserRouter,
    Routes,
    Route, Navigate, Outlet
} from "react-router-dom";

import Nopage from "./Page/Nopage";
import useNetwork from "./Network/useNetwork";
import Login from "./Auth/Login";
import Registration from "./Auth/Registration";
import OfflinePage from "./Page/OfflinePage";
import Dashboard from "./User/Dashboard";
import CourseDetails from "./Component/CourseDetails";
import AllCourses from './Component/AllCourses';
import Checkout from './Component/Checkout';
import Lecture from './Page/Lectures/Lecture';
import MyCourse from "./User/MyCourse";
import PaymentSuccess from "./Component/SuccessPayment";
import Training from "./Training/Training";
import About from "./Page/About";
import Terms from "./Page/Terms";
import FAQ from "./Page/FAQ";
import QuizMain from "./Training/QuizMain";
import TrainingCategory from "./Training/Practice/TrainingCategory";
import TrainingLevel from "./Training/Practice/TrainingLevel";
import Lesson from "./Training/Practice/Lesson";
import PracticeHistory from "./Training/Practice/PracticeHistory";
import PracticeDetails from "./Training/Practice/PracticeDetails";
import ExamCategory from "./Exam/Partials/ExamCategory";
import ExamLevel from "./Exam/Partials/ExamLevel";
import ExamLesson from "./Exam/Partials/ExamLesson";
import ExamMain from "./Exam/ExamMain";
import ExamTimeOver from './Exam/ExamTimeOver';
import Affiliate from './affiliate/Affiliate';
import CourseQuizSection from './Page/Lectures/CourseQuizSection';
import Page from './Component/Page';

import MyWishlist from './User/MyWishlist';

import ScrollToTop from './Component/ScrollToTop';
import ExamHistory from './Exam/ExamHistory';
import ExamResultDetail from './Exam/ExamResultDetail';
import ForgetPassword from './Auth/ForgetPassword';
import OtpVerification from './Auth/OtpVerification';
import UpdatePassword from './Auth/UpdatePassword';

export default function Main() {
    const networkState = useNetwork();
    const {
        online,
        since,
        downLink,
        downLinkMax,
        effectiveType,
        rtt,
        saveData,
        type,
    } = networkState;
    return (
        <React.StrictMode>
            <BrowserRouter>
            <ScrollToTop/>
                <Routes>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} element={online ? (<App/>) : <OfflinePage/>}/>
                    <Route path="*" element={<Nopage/>}/>
                    <Route path={`${process.env.PUBLIC_URL}/login`} element={online ? (<Login/>) : <OfflinePage/>}/>
                    <Route path={`${process.env.PUBLIC_URL}/registration`} element={online ? (<Registration/>) : <OfflinePage/>}/>
                    <Route path={`${process.env.PUBLIC_URL}/forget-password`} element={online ? (<ForgetPassword/>) : <OfflinePage/>}/>
                    <Route path={`${process.env.PUBLIC_URL}/forget-password/otp-verify`} element={online ? (<OtpVerification/>) : <OfflinePage/>}/>
                    <Route path={`${process.env.PUBLIC_URL}/forget-password/update`} element={online ? (<UpdatePassword/>) : <OfflinePage/>}/>

                    <Route path={`${process.env.PUBLIC_URL}/page/:id/:slug`}
                           >
                        <Route path="" element={online ? (<Page/>) : <OfflinePage/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/course/:id/:slug`}
                           element={<LoginCallbackOutlet/>}>
                        <Route path="" element={online ? (<CourseDetails/>) : <OfflinePage/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/courses`}
                           element={online ? (<AllCourses/>) : <OfflinePage/>}/>

                    <Route path="/dashboard" element={<PrivateOutlet/>}>
                        <Route path="" element={<Dashboard/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/checkout/:id/:slug`} element={<PrivateOutlet/>}>
                        <Route path="" element={<Checkout/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/lecture/:id/:slug`} element={<PrivateOutlet/>}>
                        <Route path="" element={<Lecture/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/course_quiz/:id/:slug`} element={<PrivateOutlet/>}>
                        <Route path="" element={<CourseQuizSection/>}/>
                    </Route>
                    <Route path="/my-courses" element={<PrivateOutlet/>}>
                        <Route path="" element={<MyCourse/>}/>
                    </Route>
                    <Route path="/my-wishlist" element={<PrivateOutlet/>}>
                        <Route path="" element={<MyWishlist/>}/>
                    </Route>

                    <Route path="/payment-success" element={<PrivateOutlet/>}>
                        <Route path="" element={<PaymentSuccess/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/about`} element={online ? (<About/>) : <OfflinePage/>}/>
                    <Route path={`${process.env.PUBLIC_URL}/terms_condition`} element={online ? (<Terms/>) : <OfflinePage/>}/>
                    <Route path={`${process.env.PUBLIC_URL}/faq`} element={online ? (<FAQ/>) : <OfflinePage/>}/>

                    <Route path={`${process.env.PUBLIC_URL}/student-dashboard`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<Training/>) : <OfflinePage/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/student-quiz/:id`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<QuizMain/>) : <OfflinePage/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/training/practice`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<TrainingCategory/>) : <OfflinePage/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/training/practice/level/:id`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<TrainingLevel/>) : <OfflinePage/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/training/lesson/:id`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<Lesson/>) : <OfflinePage/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/training/practice/history`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<PracticeHistory/>) : <OfflinePage/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/practice/history/details/:id`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<PracticeDetails/>) : <OfflinePage/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/exam/category`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<ExamCategory/>) : <OfflinePage/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/exam/level/:id`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<ExamLevel/>) : <OfflinePage/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/exam-level/lesson/:id`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<ExamLesson/>) : <OfflinePage/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/student-exam/:id`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<ExamMain/>) : <OfflinePage/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/exam-timeover`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<ExamTimeOver/>) : <OfflinePage/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/exam/history`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<ExamHistory/>) : <OfflinePage/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/exam/history/details/:id`} element={<PrivateOutletTraining/>}>
                        <Route path="" element={online ? (<ExamResultDetail/>) : <OfflinePage/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/affiliate`} element={<PrivateOutlet/>}>
                        <Route path="" element={online ? (<Affiliate/>) : <OfflinePage/>}/>
                    </Route>

                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
}

function useAuth() {

    if (localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined') {
        return true;
    }
    return false;
}

function isTrainingUser() {
    if (localStorage.getItem('token') && localStorage.getItem('is_training')) {
        return true;
    }
    return false;
}

function PrivateOutlet() {
    const auth = useAuth();
    // if(!auth){
    //     localStorage.setItem("url", window.location.href);
    // }else{
    //      localStorage.setItem("url",null);
    // }
    return auth ? <Outlet/> : <Navigate to="/login"/>;
}

function LoginCallbackOutlet() {
    const auth = useAuth();
    if(!auth){
        // localStorage.setItem("url", window.location.href);
    }else{
         // localStorage.setItem("url",null);
    }
    return auth ?<Outlet/>:<Outlet/>;
}

function PrivateOutletTraining() {
    const auth1 = isTrainingUser();
    // if(!auth1){
    //     localStorage.setItem("url", window.location.href);
    // }else{
    //      localStorage.setItem("url",null);
    // }
    return auth1 ? <Outlet/> : <Navigate to="/"/>;
}
