import React, {Component, Fragment, props, useState, useEffect} from "react";
import Course from "../Props/Course";
import API from "../Network/API";
import Footer from "./Footer";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import ContentLoader, { Facebook,Code  } from 'react-content-loader';
import CoursePlaceholder from "../Network/CoursePlaceholder";
import Pagination from "react-js-pagination";

export default function AllCourses(){
    const [courses,setCourses] = useState([{id:1},{id:2},{id:3},{id:4}]);
    const [hot_courses,setHotCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [current_page, setCurrentPage] = useState([]);
    const [per_page, setPerPage] = useState([]);
    const [total_page, setTotalPage] = useState([]);

    const fetchData = async (pageNumber = 1) => {
        setLoading(true);
        API.get(`/courses?page=${pageNumber}`).then(response => {
            setCourses(response.data.data.all_courses);
            setCurrentPage(response.data.data.current_page);
            setPerPage(response.data.data.per_page);
            setTotalPage(response.data.data.total_page);
            setLoading(false);
        })
    };
    useEffect(() => {

        fetchData();
        API.get(`/hot_courses`).then(response => {
            setLoading(true);
            setHotCourses(response.data.data);
            setLoading(false);
        })
     
    }, []);
    return (
        <Fragment>
            <Nav/>
            <div className="categoryDetails"> 
  <div className="categoryDetailsPadding pt-60 pb-40">
    <div className="container">
      <div className="pageTitle">
        <h4 className="mb-2">All Courses</h4>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page">All Courses</li>
          </ol>
        </nav>
      </div>
      <div className="row justify-content-center pb-40">
      {loading && courses?.map(course => (
        <CoursePlaceholder/>
       ))}
      {!loading && courses?.map(course => (
                        <Course key={course.id} data={course} />
                        ))}
      </div>
        <div className="row justify-content-start pb-20">
            { total_page>12?
                <div>
                    <Pagination
                        activePage={current_page ? current_page : 0}
                        itemsCountPerPage={per_page ? per_page : 0}
                        totalItemsCount={total_page ? total_page : 0}
                        onChange={(pageNumber) => {
                            fetchData(pageNumber)
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First Page"
                        lastPageText="Last Lage"
                    />
                </div>
           :'' }
        </div>
      <h4>Featured Courses</h4>
      <div className="row">
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators d-block d-sm-none">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={0} className aria-label="Slide 1" />
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={1} aria-label="Slide 2" className="active" aria-current="true" />
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={2} aria-label="Slide 3" className />
          </div>
          <div className="carousel-inner">
          {loading && <Facebook  />}
          {!loading && hot_courses?.map((hot_course,i) =>(
            <div className={`carousel-item bg-light-white ${i==0? `active` : `inactive`}`}>
              <div className="container">
                <div className="row align-items-center pt-20 pb-20">
                  <div className="col-md-5 col-12 ">
                    <img src={hot_course.cover} className="mx-auto rounded" alt="{hot_course.slug}" />
                  </div>
                  <div className="col-md-7 col-12">
                    <div className="card-body">
                      <h5 className="course-title">{hot_course.name}</h5>
                      <p className="author-name">Course by {hot_course.teacher}</p>
                      <p className="course-description">{hot_course.short_details}</p>
                      <div className="rating">
                        <span className="rating-count">{hot_course.avg_rating}</span>
                        <span className="rating-icon">
                        <i className={`fas fa-star ${hot_course.avg_rating>0?'':'text-dark'} `}></i>
                                    <i className={`fas fa-star ${hot_course.avg_rating>1?'':'text-dark'} `}></i>
                                    <i className={`fas fa-star ${hot_course.avg_rating>2?'':'text-dark'} `}></i>
                                    <i className={`fas fa-star ${hot_course.avg_rating>3?'':'text-dark'} `}></i>
                                    <i className={`fas fa-star ${hot_course.avg_rating>=5?'':'text-dark'} `}></i>
                        </span>
                        <span className="total-rating">{hot_course.total_rating}</span>
                      </div>
                      <Link to={`/course/${hot_course.id}/${hot_course.slug}`} className="btn btn-dark custom-btn">{hot_course.currency_code} {hot_course.course_price}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
               ))}
           
          </div>
          <button className="carousel-control-prev d-none d-sm-block" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="next-bg"><span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span></span>
          </button>
          <button className="carousel-control-next d-none d-sm-block" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="next-bg"><span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


            <Footer/>
        </Fragment>
    );
}