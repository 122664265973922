import React, {useEffect,Suspense, useState } from 'react'
import logo from '../logo.svg';
import {
    Link,
} from "react-router-dom";
import {createBrowserHistory} from "history";
import {useTranslation} from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'
import API from "../Network/API";
import { useDispatch, useSelector } from 'react-redux';
import { MY_PROFILE_FETCH_SUCCESS } from '../Reducer/MyProfileSlice';
const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'bn',
        name: 'বাংলা',
        // dir: 'rtl',
        country_code: 'bn',
    },
];
const loadingMarkup = (
    <div className="py-4 text-center">
        <h3>Loading..</h3>
    </div>
)

function Nav() {
    const history = createBrowserHistory();
    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const {t} = useTranslation();
    const dispatch=useDispatch();
    const profile_selector = useSelector((state) => state.profileReducer)
    const dashboard = () => {
        API.get(`/check_user`)
            .then(response => {
                dispatch(MY_PROFILE_FETCH_SUCCESS(response.data.data))
                localStorage.setItem("user_data", JSON.stringify(response.data.data));
            })
    }

    useEffect(() => {
        // localStorage.setItem("url_link", window.location.href);
        // console.log(localStorage.getItem('referral_code'))
        dashboard();

        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title');
        // API.get(`/check_user`).then(response => {
        //     if (response.data.success === true) {
        //         localStorage.setItem("user_data", JSON.stringify(response.data.data));
        //         // console.log(localStorage.getItem("user_data"))
        //     }else{
        //         localStorage.clear();
        //         window.location.href = "/";
        //     }
        // }).catch((error) => {
        //     localStorage.clear();
        // });
    }, [currentLanguage, t]);

    const logout = (response) => {
        localStorage.clear();
        window.location.href = "/login";
    };
    const [isActive, setIsActive] = useState(false);
    const handleClick = event => {
  
        setIsActive(current => !current);
      };


    return (

        // <div className="header">
        <div>
             <span class={`screen-darken  ${isActive ? 'active' : ''}`}></span>
            <div className="container">
                <div className="offcanvas-bars d-flex d-lg-none align-items-center">
                    <a className="navbar-brand d-flex align-items-center logo-img" href="#">
                        <img src={logo} alt="" className="logo"/>
                    </a>
                    <button data-trigger="navbar_main" onClick={handleClick} className="btn offcanvas-bars-btn ms-auto" type="button">
                        <i className="fas fa-bars"></i>
                    </button>
                </div>
            </div>

            <nav class={`navbar navbar-expand-lg header-style-1 mobile-offcanvas ${isActive ? 'show' : ''}`} id="navbar_main">
                <div className="container">
                    <a className="navbar-brand d-flex align-items-center logo-img" href="/">
                        <img src={logo} alt="" className="logo"/>
                    </a>
                 
                    <div className="offcanvas-header">
                        <div className="offcanvas-close">
                            <button className="btn-close btnClose float-end" onClick={handleClick}></button>
                        </div>
                    </div>
                   
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">

                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/">{t('home')}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/courses">Courses</Link>
                        </li>
                        {profile_selector?.data?.is_training==1?
                        <li className="nav-item">
                            <Link className="nav-link" to="/student-dashboard">Training

                            </Link>
                        </li>
                        :''}

                        <li className="nav-item">
                            <Link className="nav-link" to="/page/2/About-Us">About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/page/4/Latest-News-and-Events">News & Event</Link>
                        </li>

                    </ul>
                    
                    <div className="d-flex">
                        <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                {t('language')}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                {languages.map(({code, name, country_code}) => (
                                    <li key={code}><a className={classNames('dropdown-item', {
                                        disabled: currentLanguageCode === code,
                                    })} href="#" onClick={() => {
                                        i18next.changeLanguage(code)
                                    }}> 
                                    <span
                                        className={`fa fa-${country_code} mx-2`}
                                        style={{
                                            opacity: currentLanguageCode === code ? 0.5 : 1,
                                        }}
                                    ></span>
                                        {name}</a></li>
                                ))}
                            </ul>
                        </div>

                        {
                            history.location.pathname == '/login' || localStorage.getItem('isLoggedIn') ? "" :
                                <Link className="btn me-2 custom-btn btn-login" to="/login">Login</Link>
                        }
                        {localStorage.getItem('isLoggedIn') ?
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="/assets/image/user.svg" alt="" className="rounded-circle" height="40px"
                                         width="40px"/>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><Link className="dropdown-item" to="/dashboard">Dashboard</Link></li>
                                    <li><a className="dropdown-item" onClick={logout} href="#">Logout</a></li>
                                </ul>
                            </div>
                            : <Link className="btn custom-btn btn-reg" to="/registration">Registration</Link>}

                    </div>

                
                </div>
            </nav>
            
            <div className='header-space'></div>
            </div>

        
    );
}

export default Nav;