import { Fragment, useEffect, useState } from "react";
import Footer from "../Component/Footer";
import Nav from "../Component/Nav";
import SideBar from "./partials/SideBar";
import API from "../Network/API";
import ContentLoader, { Facebook, Code } from 'react-content-loader';
import { Link } from "react-router-dom";
function Dashboard() {
  const [courses, setCourse] = useState([]);
  const [dashboard, setDashboard] = useState([]);
  const [events, setEvent] = useState([]);
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.get(`/dashboard`).then(response => {
      setDashboard(response.data.data.total_purchased);
      setOrder(response.data.data.order_history);
      setCourse(response.data.data.on_going_course);
      setLoading(false);
    })

    API.get(`/events`).then(response => {
      setEvent(response.data.data);
      // console.log(response.data.data)
    })
  }, []);
  return (
    <Fragment>
      <Nav />
      <div className="studentDashboard">
        <div className="padding">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <div className="DashboardSideBar ">
                  <SideBar />
                </div>
              </div>
              <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="StudentDashboardContent">
                  <span>Welcome to</span>
                  <h4 className>Student dashboard</h4>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div className="card bg-light-white">
                        <div className="card-body">
                          <p className="mb-1">Total Purchased Courses</p>
                          <h2 className="mb-2">{dashboard}</h2>
                          <div className="d-flex justify-content-end">
                            <button className="btn btn-link btn-link-color"><Link to="/my-courses">View List</Link></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card bg-light-white">
                        <div className="card-body">
                          <p className="mb-1">Total Instructors Subscribing</p>
                          <h2 className="mb-2">{dashboard}</h2>
                          <div className="d-flex justify-content-end">
                            <button className="btn btn-link btn-link-color">View List</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <h6>Ongoing Courses</h6>
                    {!loading && courses?.map((course, index) => (
                      <div className="col-6">
                        <div className="card shadow border-0">
                          <div className="card-body">
                            <div className="d-flex mb-3">
                              <img src={course.course_image} alt height="50px" width="50px" className="rounded-circle" />
                              <a className="ms-auto" href="#"><i className="fas fa-ellipsis-v" /></a>
                            </div>
                            <h6 className="mb-1">{course.course_name}</h6>
                            <div className="d-flex align-items-center">
                              <img src={course.teacher_image} alt height="25px" width="25px" className="rounded-circle me-2" />
                              <span>{course.course_teacher}</span>
                            </div>
                            <div className="progressMargin">
                              <div className="progress" style={{ height: 5 }}>
                                <div className="progress-bar bg-success " role="progressbar" style={{ width: course.complete_course_progress + '%' }} aria-valuenow={course.complete_course_progress} aria-valuemin={0} aria-valuemax={100}>
                                  <span className="progress-value">{course.complete_course_progress}% Completed</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="sorting d-flex mt-3 mb-3">
                        <p>Purchased History</p>
                        <div className="ms-auto w-20">
                          <select className="form-select form-select-sm formCircle" aria-label="Default select example">
                            <option selected>All Time</option>
                            <option value={1}>One</option>
                            <option value={2}>Two</option>
                            <option value={3}>Three</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="bg-light-white rounded">
                      <div className="table-responsive">
                        <table className="table text-center">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th className="text-center">Course</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading && order?.map((value, index) => (
                              <tr>
                                <th scope="row">{index}</th>
                                <td>{value.order_date}</td>
                                <td>{value.course_name}</td>
                                <td>{value.amount}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <div className="dashboardEvent">
                  <h6>Upcoming Events</h6>
                  <div className="eventImg">
                    {events.length>0 && events?.map((value, x) => (
                      <a href="#" key={x}><img src={value.image} alt className="rounded mb-3" /></a>
                    ))}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
}
export default Dashboard;