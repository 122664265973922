import React, {Component, Fragment} from "react";
import logo from './logo.svg';
import Nav from "./Component/Nav";
import Banner from "./Component/Banner";
import CourseHome from "./Component/CourseHome";
import NewCourseHome from "./Component/NewCourseHome";
import AwardHome from "./Component/AwardHome";
import SmartLevelHome from "./Component/SmartLevelHome";
import ResourceHome from "./Component/ResourceHome";
import Footer from "./Component/Footer";

function App() {
  return (
      <Fragment>
        <Nav/>
        <Banner/>
        <CourseHome/>
        <NewCourseHome/>
        {/* <AwardHome/> */}
        {/* <SmartLevelHome/> */}
        <ResourceHome/>
        <Footer/>
      </Fragment>
  );
}

export default App;
