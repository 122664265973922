import React, {Fragment} from "react";

export default function NoticeBoard() {
    return (
        <Fragment>
            <div className="dashboardEvent d-none">
                <h6>Notice Board</h6>
                <div className="row">
                    <div className="col-12">
                        <div
                            className="bg-light py-2 px-2 rounded d-flex mb-3 justify-content-between align-items-center align-items-center shadow-sm">
                            <img src="assets/image/18.jpg" width="85" alt=""
                                 className="rounded me-2"/>
                            <div className="flex-grow-1">
                                <h6 className="mb-1 fs-16">The Advanced Web Development</h6>
                                <p className="mb-0">Development</p>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </Fragment>
    );
}