import Nav from "../Component/Nav";
import React, {Fragment, useState} from "react";
import Footer from "../Component/Footer";
import API from "../Network/API";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

function OtpVerification() {
    const [email, setEmail] = useState('');
    const [otp_number, setOtpNumber] = useState('');
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);
        var data = {
            'email': localStorage.getItem('email'),
            'otp_number': otp_number,
        }
        API.post('verify-otp', data, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then(response => {
            setLoading(false);
            if (response.data.success === true) {
                toast.success('OTP verified successfully');
                localStorage.setItem("otp_number", otp_number);
                window.location.href = "/forget-password/update";
            }
        }).catch((error) => {
            setLoading(false);
            toast.error('Please try again.');
            });
    }

    return (
        <Fragment>
            <Nav/>
            <div className="loginPage">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7 col-12">
                            <div className="loginTextArea">
                                <div className="loginHeading">
                                    <h1 className="mb-0 orangeRed">Welcome back TO</h1>
                                    <h1>SMART BRAIN</h1>
                                </div>
                                <p>“Let’s join in bettering our children’s
                                    performance with the help of experts.”</p>
                                <div className="loginBannerImg">
                                    <img src="assets/image/login-banner.svg" alt=""/>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-5 col-12">
                            <div className="loginFrom">

                                <form onSubmit={handleSubmit}>

                                    <h3 className="loginTitle">OTP Verification</h3>
                        
                                    <div className="row mb-3">
                                        <div className="col-5"><span className="line-border"></span></div>
                                        <div className="col-2 text-center"><span className="line-text">or</span></div>
                                        <div className="col-5"><span className="line-border"></span></div>
                                    </div>

                             
                                    <div className="form-group mb-3">
                                        <label htmlFor="otp" className="form-label">OTP Number</label>
                                        <input required onChange={(e) => setOtpNumber(e.target.value)}
                                               className="form-control form-control-md" type="text" name=""
                                               id="otp"/>
                                    </div>
                                 

                                    <div className="mb-3">
                                        <button type="submit" className="btn btn-lg bg-black w-100 text-white">Verify OTP{isLoading ? (
                                            <span
                                                className="spinner-border spinner-border-sm ml-5"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            <span></span>
                                        )}
                                        </button>
                                        <p className="mt-2 mb-0">Don’t have an account? <a href="/registration"
                                                                                           className="orangeRed btn-link">Sign
                                            up</a></p>
                                    </div>

                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
    );
}

export default OtpVerification;