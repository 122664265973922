import React, {Fragment, props, useState, useEffect} from "react";
import API from "../../Network/API";
import Footer from "../../Component/Footer";
import Nav from "../../Component/Nav";
import LectureSection from "./LectureSection";
import VideoContent from "./VideoContent";
import VideoPlayer from "./VideoPlayer";
import {BrowserRouter as Router, Redirect, Switch, Route, Link, useParams} from "react-router-dom";
import CourseQuiz from "./quiz/CourseQuiz";
export default function CourseQuizSection(){
    const [lectureData,setLecture] = useState([]);
    const [sections,setSection] = useState([]);
    const [courses,setCourses] = useState([]);
    const [quizes,setQuizes] = useState([]);
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([]);
    useEffect(() => {
      setLoading(true);
        if (localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined') {
            API.get(`/private_section_quiz/${id}`).then(response => {
                setLecture(response.data.data.lecture);
                setQuizes(response.data.data.lecture.quizes);
                setSection(response.data.data.section);
                setCourses(response.data.data.course);
                setLoading(false);
            }) 
            API.get(`/check_course_mark?data=${id}`).then(response => {
              setContent(response.data.data)
            })
        }else{
            setLoading(false);
        }
  
    }, [id]);
    function handleCheck(val) {
      return content.some(item => val === item);
    }
    return(
        <Fragment>
        <Nav/>
     <div className="courseDetails">
  <div className>
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-8 col-xxl-8">
          {quizes.length>0?
          <CourseQuiz data={lectureData} quizes={quizes} sections={sections} load={loading}/>
          :''
}
          
        </div>
        <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4 col-xxl-4">
           
        <div className="coursePriceSection">
        <div className="card bg-light-white shadow-sm">
          <h4 className="learning-video-title">Course content</h4>
          <div className="learnContentAccordion">
            <div className="accordion accordion-flush  bg-light-white" id="accordionFlushExample">
              {sections?.map((section, index) => (
                <div key={index}>
                  {section.total_lecture > 0 ?
                    <div className="accordion-item bg-light-white">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseOne${section.id}`} aria-expanded="false" >
                          <div className="videoContent">
                            <span className="title">{section.title}</span>
                            <span className="duration">{section.total_lecture} | {section.total_hour}</span>
                          </div>
                        </div>
                      </h2>
                      <div id={`flush-collapseOne${section.id}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample" style={{}}>
                        <div className="accordion-body bg-white" style={{padding:'0.6rem 0.6rem'}}>
                          <div className="d-flex">

                            <div className="flex-grow-1">

                              <div className="rating mt-0">
                                {/* <ul className="courseVideoListing">
          
                                  {section.lecture?.map((lecture, index) => (
                                    <div key={index}>
                                      {lecture.is_paid == 0 ?
                                                  <li><Link style={!handleCheck(lecture.id) ? {pointerEvents: "none"} : null} to={`${process.env.PUBLIC_URL}/lecture/${lecture.uid}/${lecture.slug}`}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3 ${id == lecture.uid ? `text-success` : ``}`}></i>{lecture.title} </Link></li>
                                                  :

                                                  <li><Link style={!handleCheck(lecture.id) ? {pointerEvents: "none"} : null}  to={`${courses.is_purchased == 1 && lecture.type == 0 ? `${process.env.PUBLIC_URL}/lecture/${lecture.uid}/${lecture.slug}` : courses.is_purchased == 1 && lecture.type == 1 ? `${process.env.PUBLIC_URL}/course_quiz/${lecture.uid}/${lecture.slug}` : ""}`}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3 ${id == lecture.uid ? `text-success` : ``}`}></i>{lecture.title}</Link></li>
                                                }
                                    </div>
                                  ))}
                                </ul> */}

<ul className="courseVideoListing">
                                          <>
                                                            {section?.sub_sections?.map((sect, k) => (
                                                                    <>
                                                                    {sect?.lectures.length>0?
                                                                <div key={k} className="accordion-item bg-light-white">
                                                                    <div className="accordion-header" id={`headingOne${sect.id}`}>
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOneSec${sect.id}`} aria-expanded="false" aria-controls="collapseOneSec">
                                                                            {sect.title}
                                                                        </button>
                                                                    </div>
                                                                    <div id={`collapseOneSec${sect.id}`} className="accordion-collapse collapse" aria-labelledby={`headingOne${sect.id}`} data-bs-parent="#accordionExample1" >
                                                                        <div className="accordion-body bg-white">
                                                                            <ul className="courseVideoListing">
                                                                                {sect?.lectures?.map((lecture, k) => (
                                                                                    <>
                                                                                                         {lecture.is_paid == 0 ?
                                                  <li><Link style={!handleCheck(lecture.id) ? {pointerEvents: "none"} : null} to={`${process.env.PUBLIC_URL}/lecture/${lecture.uid}/${lecture.slug}`}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3 ${id == lecture.uid ? `text-success` : ``}`}></i>{lecture.title} </Link></li>
                                                  :

                                                  <li><Link style={!handleCheck(lecture.id) ? {pointerEvents: "none"} : null}  to={`${courses.is_purchased == 1 && lecture.type == 0 ? `${process.env.PUBLIC_URL}/lecture/${lecture.uid}/${lecture.slug}` : courses.is_purchased == 1 && lecture.type == 1 ? `${process.env.PUBLIC_URL}/course_quiz/${lecture.uid}/${lecture.slug}` : ""}`}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3 ${id == lecture.uid ? `text-success` : ``}`}></i>{lecture.title}</Link></li>
                                                }
                                                                                    </>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                                :''}
                                                                </>

                                                            ))}
                                                        </>
                                            {section.lecture?.map((lecture, index) => (
                                              <div>
                                                {lecture.is_paid == 0 ?
                                                  <li><Link style={!handleCheck(lecture.id) ? {pointerEvents: "none"} : null} to={`${process.env.PUBLIC_URL}/lecture/${lecture.uid}/${lecture.slug}`}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3 ${id == lecture.uid ? `text-success` : ``}`}></i>{lecture.title}</Link></li>
                                                  :

                                                  <li><Link style={!handleCheck(lecture.id) ? {pointerEvents: "none"} : null}  to={`${courses.is_purchased == 1 && lecture.type == 0 ? `${process.env.PUBLIC_URL}/lecture/${lecture.uid}/${lecture.slug}` : courses.is_purchased == 1 && lecture.type == 1 ? `${process.env.PUBLIC_URL}/course_quiz/${lecture.uid}/${lecture.slug}` : ""}`}><i className={`fas ${lecture.type == 1 ? 'fa-file' : 'fa-play-circle'} me-3 ${id == lecture.uid ? `text-success` : ``}`}></i>{lecture.title}</Link></li>
                                                }
                                              </div>
                                            ))}
                                          </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
              </div>
      </div>
    </div>
  </div>
</div>

        <Footer/>
        </Fragment>
    );
}