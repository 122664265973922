import { Fragment } from "react";
export default CourseRequirment;
function CourseRequirment({ requirement}){
    return (
        <Fragment>
<div class="courseRequirementsSection mt-42">
                        <h1 class="courseDetailsHeading">Requirements</h1>
                        <div class="courseRequirementDec">
                        <p className='course-des-text'>{requirement}</p>
                        </div>
                    </div>
        </Fragment>
    );
}