import React, {Fragment, useEffect, useState} from "react";
import Nav from "../../Component/Nav";
import Footer from "../../Component/Footer";
import TrainingLeftNav from "../TrainingLeftNav";
import NoticeBoard from "../NoticeBoard";
import API from "../../Network/API";
import ContentLoader, {Facebook, Code} from 'react-content-loader';
import {Link} from "react-router-dom";

export default function PracticeHistory() {
    const [practice_histories, setPracticeHistories] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        API.get(`/practice_history`).then(response => {
            setPracticeHistories(response.data.data);
            setLoading(false);
        })
    }, [])
    return (
        <Fragment>
            <Nav/>
            <div className="studentDashboard">
                <div className="padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <TrainingLeftNav/>
                            </div>
                            <div className="col-12 col-sm-8 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                                <div className="StudentDashboardContent">
                                    <h4 className=""> Practice History </h4>
                                    <div className="row mb-3">
                                        <div className="rounded">
                                            <div className="table-responsive">
                                                {loading && practice_histories?.map(practice => (
                                                    <Code/>
                                                ))}
                                                {!loading ?
                                                    <table className="table">
                                                        <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Practice Name</th>
                                                            <th>Total Questions</th>
                                                            <th>Total Answered</th>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {!loading && practice_histories?.map(practice => (
                                                            <tr>
                                                                <th scope="row">{practice.id}</th>
                                                                <td>{practice.level_name}</td>
                                                                <td>{practice.total_question}</td>
                                                                <td>{ practice.total_answer }</td>
                                                                <td>{ practice.start_date }</td>
                                                                <td>{ practice.end_date }</td>
                                                                <td>
                                                                    <Link to={`/practice/history/details/${practice.uuid}`} className={`btn btn-sm btn-success`}>Details</Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                    : " "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </Fragment>
    );
}