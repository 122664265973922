import { Fragment } from "react";
import { Facebook } from "react-content-loader";

export default function VideoPlayer({ data,load }){
    return (
        <Fragment>
             <div className="video-thamble text-center">
             {load && <Facebook  />}

                {!load && data.upload_type==2 ? <iframe width="100%" height="415" src={data.video_url} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                :
                <video width="100%"  height="415" controls src={data.video_attachment} type="video/mp4">
                    
                </video>
                }
                
          </div>
        </Fragment>
    );
}